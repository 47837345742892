import { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "../axios";

export default function useForm(initialState) {
  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const auth = useContext(AuthContext);

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const clearErrors = () => {
    const updatedState = { ...state };
    for (const item in updatedState) {
      updatedState[item].error = "";
    }
    setState(updatedState);
  };

  const handleSubmit = async (e, url, method, moreData = {}) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors([]);
    clearErrors();

    if (!state.checked.value) {
      const updatedState = { ...state };
      updatedState.checked.error = "Začiarknite toto políčko";
      setState(updatedState);
      setLoading(false);
      return false;
    }

    try {
      const formData = { ...moreData };
      for (const item in state) {
        formData[item] = state[item].value;
      }

      await axios[method](url, formData, {
        headers: { Authorization: `Bearer ${auth.token}`, "special-token": `Bearer ${auth.specialToken}` }
      });
      setSuccess(true);
      handleInput("checked", false);
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Niečo sa pokazilo"]);
      }
      setLoading(false);
      return false;
    }
    setLoading(false);
    return true;
  };

  return {
    state,
    setState,
    loading,
    success,
    errors,
    setErrors,
    handleInput,
    handleSubmit,
  };
}
