import React from "react";
import {Link} from 'react-router-dom'

export default function PageNav(props) {
  if (!props.title) {
    return (
      <div className="page-header telf" style={{ margin: "0.5rem 0 0.5rem" }}>
        <div className="page-leftheader"></div>
        <div className="btn btn-list"></div>
      </div>
    );
  }

  return (
    <div className="page-header">
      <div className="page-leftheader">
        <h4 className="page-title mb-0">{props.title}</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">
              <i className="fa fa-bar-chart mr-2 fs-14"></i>Dashboard
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <a href="#0">{props.subtext}</a>
          </li>
        </ol>
      </div>
      <div className="page-rightheader"></div>
    </div>
  );
}
