import { useContext, useEffect, useState } from 'react'
import styles from './index.module.css'
import { AuthContext } from '../../context/AuthContext'
import axios from '../../axios'

const format = (more) => new Intl.NumberFormat('sk-SK', {
  style: "currency",
  currency: "EUR",
  ...more
}).format;

const Info = (props) => {
  const { token, userId } = useContext(AuthContext)
  const [info, setInfo] = useState(null);

  const getWalletInfo = async () => {
    try {
      const res = await axios.get(`/user/wallet/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      setInfo(res.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getWalletInfo();
    const timer = setInterval(getWalletInfo, 10 * 10000);
    return () => clearInterval(timer);
  }, [props.update])

  return (
    <section className={[styles.info, 'fix-bg'].join(" ")}>
      <div>
        <h2 className={styles.my_wallet}>MOJA PEŇAŽENKA</h2>
        <p className={styles.description}>Táto časť obsahuje informácie o vašich finančných prostriedkoch, ktoré máte k dispozícii na obchodovanie.</p>
      </div>
      <div>
        <h3 className={styles.my_capital}>MÔJ VOĽNÝ KAPITÁL</h3>
        <h4 className={styles.amount}>{format()(info?.free_capital)}</h4>
      </div>
      <div>
        <h3 className={styles.my_capital}>V obchodovaní</h3>
        <h4 className={styles.amount}>{format()(info?.in_trade)}</h4>
      </div>
      <div className={styles.controls}>
        <button className={[styles.btn, styles.green].join(" ")} onClick={props.open}>OTVORIŤ OBCHOD</button>
      </div>
    </section>
  )
}

export default Info
