import React from "react";
import useForm from "../../hooks/useForm";

export default function PasswordUpdate(props) {
  const initialState = {
    prevPassword: { value: "", error: "" },
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
    checked: { value: true, error: "" },
  };

  const {
    state,
    setState,
    loading,
    success,
    errors,
    handleInput,
    handleSubmit,
  } = useForm(initialState);

  const customHandler = async (e) => {
    e.preventDefault();
    if (state.password.value !== state.confirmPassword.value) {
      const updatedState = { ...state };
      updatedState.confirmPassword.error = "Heslá sa nezhodujú";
      return setState(updatedState);
    }

    const result = await handleSubmit(e, "/user/change-password", "put", { userId: props.userId });
    if (result !== false) {
      setState({...initialState});
    }
  };
  return (
      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title" style={{ textTransform: "none",}}>Zmena hesla</h4>
          </div>
          <div className="card-body">
            <form onSubmit={customHandler}>
              <div className="">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Aktuálne heslo
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Zadajte aktuálne heslo"
                    value={state.prevPassword.value}
                    onChange={(e) =>
                      handleInput("prevPassword", e.target.value)
                    }
                  />
                  <small className="text-danger block mt-1">
                    {state.prevPassword.error}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Nové heslo
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Zadajte nové heslo"
                    value={state.password.value}
                    onChange={(e) => handleInput("password", e.target.value)}
                  />
                  <small className="text-danger block mt-1">
                    {state.password.error}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Potvrdiť heslo
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Zadajte nové heslo ešte raz"
                    value={state.confirmPassword.value}
                    onChange={(e) =>
                      handleInput("confirmPassword", e.target.value)
                    }
                  />
                  <small className="text-danger block mt-1">
                    {state.confirmPassword.error}
                  </small>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary mt-4 mb-0"
                disabled={loading}
              >
                {loading ? "Mení sa..." : "Zmeniť"}
              </button>
              {success && (
                <small className="text-success block mt-2">
                  Kredit bol úspešne stiahnutý
                </small>
              )}
              {errors &&
                errors.map((error, i) => (
                  <small key={`error-${i}`} className="text-danger block mt-2">
                    {error}
                  </small>
                ))}
            </form>
          </div>
        </div>
      </div>
  );
}
