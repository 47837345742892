import React from "react";
import Layout from "../../components/Layout";
import PageNav from "../../components/PageNav";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import styles from './index.module.css';

export default function EditUser() {
  useCloseSidebar();

  return (
    <Layout
      pageNav={
        <PageNav
          title="Naši partneri"
          subtext="Partneri"
        />
      }
    >
      <div className="row">
        <div className={styles.partner}>
          <span className={styles["partner-type"]}>Hlavný partner</span>
          <footer className={styles['partner-footer']}>
            <h2 className={styles["partner-title"]}>G CHAT</h2>
            <p>Vývojárska spoločnosť zameriavajúca sa na umelú inteligenciu a jej výhody, ktoré prinášajú pre online podniaknie.</p>
            <a href="https://gchat.sk/" target="_blank" className={styles['partner-link']}>Viac info</a>
          </footer>
        </div>
      </div>
    </Layout>
  );
}
