import { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";
import Cross from "../../assets/Cross";
import { useNavigate } from "react-router-dom";

const boxShadow =
  "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px ";

const Activate = ({ tradeStatus }) => {
  const [state, setState] = useState(null);
  const [status, setStatus] = useState();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/")
  }

  useEffect(() => {
    setState(tradeStatus === "pending" ? "request_send" : null);
  }, [tradeStatus]);

  const sendRequst = async () => {
    setStatus("loading");
    try {
      await axios.post(
        "/user/request-trade",
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        },
      );
    } catch (err) {
      setStatus(
        "Pri odosielaní požiadavky došlo k chybe, skúste to prosím neskôr",
      );
      console.log(err);
    }
    setState("request_send");
  };

  if (state === "request_send")
    return (
      <div
        className={[styles.popup, "py-6", "fix-bg"].join(" ")}
        style={{ boxShadow, width: "min(90%, 600px)" }}
      >
        <span
          style={{ position: "absolute", right: "10px", top: "10px" }}
          onClick={handleClose}
        >
          <Cross />
        </span>
        <h2 className={styles.title}>VAŠA ŽIADOSŤ BOLA ODOSLANÁ</h2>
        <p className="text-center mt-4">Vašu žiadosť skontrolujeme do 24 hodín. Ak bude všetko v poriadku, sprístupníme vám trading sekciu.</p>
      </div>
    );

  if (state === "term")
    return (
      <div
        className={[styles.popup, "py-6", "fix-bg"].join(" ")}
        style={{
          boxShadow,
          width: "min(90%, 800px)",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <span
          style={{ position: "absolute", right: "10px", top: "10px" }}
          onClick={handleClose}
        >
          <Cross />
        </span>
        <h2 className={styles.title}>Podmienky</h2>
        <p className="mb-6 mt-6">
          This section contains information about your funds available for
          trading. This section contains information about your funds available
          for trading. This section contains information about your funds
          available for trading. This section contains information about your
          funds available for trading. This section contains information about
          your funds available for trading. This section contains information
          about your funds available for trading. This section contains
          information about your funds available for trading. This section
          contains information about your funds available for trading.
        </p>
        <p className="mb-6">
          This section contains information about your funds available for
          trading. This section contains information about your funds available
          for trading. This section contains information about your funds
          available for trading. This section contains information about your
          funds available for trading. This section contains information about
          your funds available for trading. This section contains information
          about your funds available for trading. This section contains
          information about your funds available for trading. This section
          contains information about your funds available for trading.
        </p>
        <h3>Headline 1</h3>
        <p>
          This section contains information about your funds available for
          trading. This section contains information about your funds available
          for trading. This section contains information about your funds
          available for trading. This section contains information about your
          funds available for trading. This section contains information about
          your funds available for trading. This section contains information
          about your funds available for trading. This section contains
          information about your funds available for trading. This section
          contains information about your funds available for trading.
        </p>
        <button
          className={[styles.btn, styles.white, "mx-auto block mt-8 px-8"].join(
            " ",
          )}
          onClick={sendRequst}
          disabled={status === "loading"}
        >
          {status === "loading" ? "Odoslanie..." : "POTVRDZUJEM PODMIENKY"}
        </button>
        {status !== "loading" && status && (
          <small className="block text-center mt-4 text-danger">{status}</small>
        )}
      </div>
    );

  return (
    <button
      className={[
        styles.popup,
        styles.btn,
        styles.white,
        "mx-auto block",
        styles.activate_btn,
      ].join(" ")}
      style={{ padding: "8px", background: "white", width: "auto" }}
      onClick={() => setState("term")}
    >
      AKTIVOVAŤ OBCHODNÚ SEKCIU
    </button>
  );
};

export default Activate;
