import { Link } from "react-router-dom";
import "./Row.css"

const Row = (props) => {
  let bg = "#aaa";
  if (props.tradeStatus === "ok") bg = "#1DC037";
  if (props.tradeStatus === "pending") bg = "#C9A64E";

  return (
    <tr>
      <td>
        <a>
          {props.name}
        </a>
      </td>
      <td>
        <a style={{ color: "#8A8A8A" }}>
          {Number(props.deposit).toLocaleString(undefined, {
            maximumFractionDigits: 2
          })} €
        </a>
      </td>
      <td>
        <Link to={`/client/${props.id}`} className="edit-btn">Upraviť</Link>
      </td>
      <td>
        <div style={{ width: "1rem", height: "1rem", borderRadius: "50%", background: bg, boxShadow: "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset" }} />
      </td>
    </tr>
  );
};

export default Row;
