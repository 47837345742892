import { useEffect, useState } from "react";

export default function useScripts(scripts, loadCustom) {
  const [loading, setLoading] = useState(true);
  const [inProgress, setInProgress] = useState(false);

  const getScript = (src) =>
    new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.addEventListener("load", () => {
        resolve();
      });
      document.body.insertAdjacentElement("beforeend", script);
    });

  const getAllScripts = async () => {
    setInProgress(true);

    for (const script of scripts) {
      if (!document.querySelector(`script[src="${script}"]`))
        await getScript(script);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (loadCustom) getScript("../assets/js/custom.js");
  }, [loadCustom]);

  if (loading && !inProgress) getAllScripts();

  return [loading];
}
