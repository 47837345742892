import axios from "../../../axios";
import { SidebarContext } from "../../../context/SidebarContext";
import './index.css'
import { useContext, useEffect, useRef, useState } from 'react'

const Card = (item) => {
  return (
    <div className="currencyCard">
      <img className="currencyIcon" src={item.logo} />
      <span>{item.name}</span>
      <span className="currencyUsd">USD</span>
      <span>{new Intl.NumberFormat('en-US', { style: "currency", currency: "usd", maximumFractionDigits: 2 }).format(item.price)}</span>
    </div >
  )
}

const Currency = (props) => {
  const ref = useRef(null)
  const [coins, setCoins] = useState([]);
  const headers = { headers: { Authorization: `Bearer ${props.token}` } }
  const { currency } = useContext(SidebarContext)

  const getPrice = async () => {
    try {
      const res = await axios.get("/user/currency", headers);
      setCoins(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getPrice();
  }, [])

  useEffect(() => {
    const el = ref.current;
    const width = 317;
    const id = setInterval(() => {
      const limit = el.scrollWidth - el.clientWidth;
      const left = el.scrollLeft;
      if (left < limit) {
        el.scrollBy(width, 0);
      } else {
        el.scrollTo(0, 0);
      }
    }, 3000)

    return () => {
      clearInterval(id);
    }
  }, [])

  return (
    <section>
      <h2>{currency === "eur" ? "Klasický " : "Index"} investičný účet</h2>
      <div className="currencyContainer" ref={ref}>
        {coins.map((itm, idx) => <Card {...itm} index={idx + 1} key={itm._id} />)}
      </div>
    </section>
  )
}
export default Currency

