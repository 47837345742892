function Switch(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="36"
      fill="none"
      viewBox="0 0 56 32"
      style={{cursor: "pointer"}}
      onClick={props.onClick}
    >
      <rect width="32" height="12" x="8" y="10" fill="#999" rx="6"></rect>
      <g filter="url(#filter0_d_173_381)">
        <circle cx={props.on ? "38" : "15"} cy="16" r="10" fill={props.on ? "green" : "#fff"}></circle>
      </g>
      <defs>
        <filter
          id="filter0_d_173_381"
          width="56"
          height="36"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_173_381"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_173_381"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Switch;
