import axios from "../../axios"
import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Loader from "../../components/Loader"
import Layout from "../../components/Layout"
import PageNav from "../../components/PageNav"
import draftToHtml from "draftjs-to-html";
import { AuthContext } from "../../context/AuthContext"
import Popup from "./Popup";

const BlogPost = () => {
  const { slug } = useParams()
  const [post, setPost] = useState(null)
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const getPost = async () => {
    try {
      const res = await axios.get(`/blog/${slug}`)
      setPost(res.data.post);
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getPost();
  }, [slug]);

  if (!post) return <Loader />

  const markup = draftToHtml(JSON.parse(post.content));

  return (
    <Layout pageNav={<PageNav title="Blogs" subtext={post.title} />}>
      <div style={{ maxWidth: '800px', margin: "0 auto" }}>
        <header style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>{post.title}</h1>
          {auth.isAdmin && <div className="btns">
            <button className="btn btn-danger mr-4" onClick={() => setOpen(true)}>Odstrániť</button>
            <Link className="btn btn-primary" to={`/edit-blog/${slug}`}>Upraviť</Link>
          </div>}
        </header>
        <img src={post.mainPicture} />
        <p style={{ margin: "25px 0" }}>{post.description}</p>
        <main className="single-post-content_text"
          id="font_chage"
          style={{ fontSize: "12px" }}
          dangerouslySetInnerHTML={{ __html: markup }}
        >
        </main>
      </div>
      <Popup
        active={open}
        close={() => setOpen(false)}
        token={auth.token}
        slug={slug}
      />
    </Layout >
  )
}

export default BlogPost
