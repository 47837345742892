import moment from "moment";
import React, { useState } from "react";
import axios from "../../axios";

export default function Contract(props) {
  const initialState = {
    start: {
      value: moment(props.start).format("DD.MM.YYYY"),
      submit: false,
      error: "",
      success: false,
    },
    end: {
      value: moment(props.end).format("DD.MM.YYYY"),
      submit: false,
      error: "",
      success: false,
    },
  };

  const [state, setState] = useState({ ...initialState });

  const update = (type, value) => {
    const updatedState = { ...state };
    updatedState[type].value = value;
    setState(updatedState);
  };

  const cleanUp = (type) => {
    const updatedState = { ...state };
    updatedState[type].error = "";
    updatedState[type].submit = false;
    updatedState[type].success = false;
    setState(updatedState);
  };

  const handleSubmit = async (type) => {
    cleanUp(type);
    const updatedState = { ...state };
    updatedState[type].submit = true;
    setState(updatedState);

    try {
      const formData = { userId: props.userId, type, value: state[type].value };
      await axios.put("/admin/contract", formData, {
        headers: { Authorization: `Bearer ${props.token}`, "special-token": `Bearer ${props.specialToken}` },
      });
      const updatedState = { ...state };
      updatedState[type].success = true;
      setState(updatedState);
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        const updatedState = { ...state };
        updatedState[type].error = msgs;
      } else if (fieldErrors) {
        fieldErrors.forEach((fieldError) => {
          updatedState[type].error += fieldError.msg;
        });
        setState(updatedState);
      } else {
        updatedState[type].error = "Niečo sa pokazilo";
      }
    }

    const updatedState1 = { ...state };
    updatedState1[type].submit = false;
    setState(updatedState1);
  };

  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <div className="card-title" style={{ textTransform: "none", }}>Začiatok zmluvy</div>
          </div>
          <div className="card-body">
            <p className="mg-b-20 mg-sm-b-40">
              Dátum nižšie vyobrazuje presný deň podpisu zmluvy s klienta. Tento
              dátum sa fixne zobrazuje aj v dashboarde klienta.
            </p>
            <div className="wd-200 mg-b-30">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <svg
                      className="svg-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      height="18"
                      viewBox="0 0 24 24"
                      width="18"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H4V5h16zM4 21V10h16v11H4z" />
                      <path d="M4 5.01h16V8H4z" opacity=".3" />
                    </svg>
                  </div>
                </div>
                <input
                  className="form-control fc-datepicker"
                  placeholder="MM/DD/YYYY"
                  type="text"
                  readOnly={!props.isAdmin}
                  value={state.start.value}
                  onInput={(e) => update("start", e.target.value)}
                />
              </div>
            </div>
            {props.isAdmin && (
              <button
                type="button"
                className="btn btn-primary mt-4 mb-0"
                disabled={state.start.submit}
                onClick={() => handleSubmit("start")}
              >
                {state.start.submit ? "Aktualizuje sa..." : "Aktualizovať"}
              </button>
            )}
            {state.start.success && (
              <small className="text-success block mt-2">
                Úspešne aktualizovaná zmluva
              </small>
            )}
            {state.start.error && (
              <small className="text-danger block mt-2">
                {state.start.error}
              </small>
            )}
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <div className="card-title" style={{ textTransform: "none", }}>Koniec zmluvy </div>
          </div>
          <div className="card-body">
            <p className="mg-b-20 mg-sm-b-40">
              Dátum nižšie vyobrazuje posledný deň zazmluvnenia klienta. Tento
              dátum sa fixne zobrazuje aj v dashboarde klienta.
            </p>
            <div className="wd-200 mg-b-30">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <svg
                      className="svg-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      height="18"
                      viewBox="0 0 24 24"
                      width="18"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H4V5h16zM4 21V10h16v11H4z" />
                      <path d="M4 5.01h16V8H4z" opacity=".3" />
                    </svg>
                  </div>
                </div>
                <input
                  className="form-control"
                  id="datepickerNoOfMonths"
                  placeholder="MM/DD/YYYY"
                  type="text"
                  readOnly={!props.isAdmin}
                  value={state.end.value}
                  onChange={(e) => update("end", e.target.value)}
                />
              </div>
              {props.isAdmin && (
                <button
                  type="button"
                  className="btn btn-primary mt-4 mb-0"
                  disabled={state.end.submit}
                  onClick={() => handleSubmit("end")}
                >
                  {state.end.submit ? "Aktualizuje sa..." : "Aktualizovať"}
                </button>
              )}
              {state.end.success && (
                <small className="text-success block mt-2">
                  Úspešne aktualizovaná zmluva
                </small>
              )}
              {state.end.error && (
                <small className="text-danger block mt-2">
                  {state.end.error}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
