import React, { useState } from 'react'

export default function ToggleTheme() {
  const prevTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState(prevTheme);
  const body = document.querySelector("body");

  const toggleTheme = () => {
    if (theme === "dark" || !theme) {
      setTheme("light");
      localStorage.setItem("theme", "light");
      body.classList.remove("dark-mode");
    }
    else {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
      body.classList.add("dark-mode");
    }
  }

  return (
    <label className="custom-switch">
      <span className="custom-switch-description mr-2"></span>
      <input 
        type="checkbox" 
        name="custom-switch-checkbox" 
        className="custom-switch-input" 
        onClick={toggleTheme} 
        defaultChecked={theme !== "light"} 
      />
      <span className="custom-switch-indicator custom-switch-indicator-lg"></span>
    </label>
  )
}
