import React from "react";
import DropDown from "./DropDown";
import FullscreenIcon from "./FullscreenIcon";
import HeaderLogo from "./HeaderLogo";
import SearchBar from "./SearchBar";
import SearchIcon from "./SearchIcon";
import ToggleIcon from "./ToggleIcon";
import UserIcon from "./UserIcon";
import ToggleTheme from "./ToggleTheme";

export default function MyHeader() {
  return (
    <div className="app-header header top-header">
      <div className="container-fluid">
        <div className="d-flex">
          <HeaderLogo />
          <ToggleIcon />
          <SearchBar />
          <div className="d-flex order-lg-2 ml-auto">
            <SearchIcon />
            <ToggleTheme />
            <FullscreenIcon />
            <div className="dropdown profile-dropdown">
              <UserIcon />
              <DropDown />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
