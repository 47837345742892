import React from "react";
import Layout from "../../components/Layout";
import PageNav from "../../components/PageNav";
import ContactForm from "./ContactForm";
import useScripts from "../../hooks/useScripts";
import useCloseSidebar from "../../hooks/useCloseSidebar";

export default function Support() {
  useScripts([], true);
  useCloseSidebar();

  return (
    <Layout pageNav={<PageNav title="Podpora" subtext="Podpora" />}>
      <div className="row">
        <div className="col-lg-8 col-xl-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title" style={{ textTransform: "none",}}>Kontaktný formulár</h3>
            </div>
            <div className="card-body">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
