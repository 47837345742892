import React, { useEffect } from "react";
import MyHeader from "./MyHeader";
import Sidebar from "./Sidebar";
import MyFooter from "./MyFooter";
import Loader from "./Loader";

import useScripts from "../hooks/useScripts";

const scripts = ["../assets/plugins/sidemenu/sidemenu.js"];

export default function Layout(props) {
  const [loading] = useScripts(scripts, false);

  useEffect(() => {
    const body = document.querySelector("body");
    body.classList.add("app");
    body.classList.add("sidebar-mini");
    body.classList.remove("h-100vh");
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="page">
      <div className="page-main">
        <Sidebar />
        <div className="app-content main-content">
          <div className="side-app">
            <MyHeader />
            {props.pageNav}
            {props.children}
          </div>
        </div>
      </div>
      <MyFooter />
    </div>
  );
}
