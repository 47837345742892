import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import axios from "../axios";

const InfoContext = createContext();

const InfoState = (props) => {
  const auth = useContext(AuthContext);
  const [state, setState] = useState({});

  const getBasicInfo = async () => {
    try {
      const res = await axios.get("/user/basic-info", {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setState(res.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (auth.token) getBasicInfo();
  }, [auth.token]);

  return (
    <InfoContext.Provider value={state}>{props.children}</InfoContext.Provider>
  );
};

export { InfoState, InfoContext };
