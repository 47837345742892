import React, { useContext, useState, useRef } from "react";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";

export default function UserDeposit(props) {
  const initialState = {
    amount: { value: 0, error: "" },
    checked: { value: false, error: "" },
    date: { value: "", error: "" },
  };
  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const checkboxRef = useRef(false);

  const auth = useContext(AuthContext);

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const clearErrors = () => {
    const updatedState = { ...state };
    for (const item in updatedState) {
      updatedState[item].error = "";
    }
    setState(updatedState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors([]);
    clearErrors();

    if (!state.checked.value) {
      const updatedState = { ...state };
      updatedState.checked.error = "Začiarknite toto políčko";
      setState(updatedState);
      setLoading(false);
      return;
    }

    try {
      const depositData = {
        userId: props.userId,
        amount: state.amount.value,
        date: state.date.value,
        account: props.account
      };
      await axios.post("/admin/deposit-credit", depositData, {
        headers: { Authorization: `Bearer ${auth.token}`, "special-token": `Bearer ${auth.specialToken}` },
      });
      setSuccess(true);
      setState({ ...initialState });
      checkboxRef.current.checked = false;
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Niečo sa pokazilo"]);
      }
    }
    setLoading(false);
  };

  return (
    <div className="col-lg-12 col-xl-6 col-md-12 col-sm-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title" style={{ textTransform: "none", }}>Pripísanie vkladu</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Vklad v €
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Zadajte vklad v €"
                  value={state.amount.value}
                  onChange={(e) => handleInput("amount", e.target.value)}
                />
                <small className="text-danger block mt-1">
                  {state.amount.error}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1" className="form-label">Zadajte dátum pripísania</label>

                <div className="wd-200 mg-b-30">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18">
                          <path d="M0 0h24v24H0V0z" fill="none"></path>
                          <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H4V5h16zM4 21V10h16v11H4z"></path>
                          <path d="M4 5.01h16V8H4z" opacity=".3"></path>
                        </svg>
                      </div>
                    </div>
                    <input
                      className="form-control fc-datepicker"
                      placeholder="15.02.2000"
                      type="text"
                      value={state.date.value}
                      onChange={e => handleInput("date", e.target.value)}
                    />
                  </div>
                  <small className="text-danger block mt-1">
                    {state.date.error}
                  </small>
                </div>
              </div>

              <label className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="example-checkbox2"
                  value={state.checked.value}
                  ref={checkboxRef}
                  onChange={(e) => handleInput("checked", e.target.checked)}
                />
                <span className="custom-control-label">
                  Potvrdzujem pripísanie vkladu na účte klienta.
                </span>
              </label>

              <small className="text-danger block mt-0">
                {state.checked.error}
              </small>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-0"
              disabled={loading}
            >
              {loading ? "Odosielanie..." : "Pripísať vklad"}
            </button>
            {success && (
              <small className="text-success block mt-2">
                Kredit bol úspešne pridaný
              </small>
            )}
            {errors &&
              errors.map((error, i) => (
                <small key={`error-${i}`} className="text-danger block mt-2">
                  {error}
                </small>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
}
