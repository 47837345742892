import React, { useState } from "react";
import axios from "../../axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function ResetPassword() {
  const [status, setStatus] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Assuming a query param named 'name'

  if (!token) return navigate('/')

  const handleSubmit = async () => {
    if (password !== cpassword) {
      return setErrors(["Passwords doesn't match"]);
    }

    setStatus("loading");
    setErrors([]);

    try {
      await axios.post(`/user/reset-password`, { password }, { headers: { Authorization: `Bearer ${token}` } });
      setPassword("");
      setCPassword("");
      setTimeout(() => {
        navigate('/')
      }, 1000);
      return setStatus("success");
    } catch (err) {
      setErrors(["Niečo sa pokazilo"]);
    }

    setStatus(null);
  };

  const renderErrors = () =>
    errors.map((error) => (
      <small key={error} className="text-danger block mt-1 text-center">
        {error}
      </small>
    ));

  const renderStatus = () => {
    if (status === "success")
      return (
        <small className="text-capitalize text-success block mt-3 text-center">
          Successfully updated the password
        </small>
      );

    return renderErrors();
  };

  return (
    <div className="page login-container">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-center title-style mb-6">
                        <h1 className="mb-2">Zmena hesla</h1>
                        <hr />
                        <p className="text-muted">
                          Môžete si zmeniť svoje heslo
                        </p>
                      </div>
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="fe fe-lock"></i>
                          </div>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Nové heslo"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="fe fe-lock"></i>
                          </div>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Potvrdiť heslo"
                          value={cpassword}
                          onChange={e => setCPassword(e.target.value)}
                        />
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn  btn-primary btn-block px-4"
                            onClick={handleSubmit}
                            disabled={status === "loading"}
                          >
                            {status === "loading" ? "Odosiela sa..." : "Resetovať"}
                          </button>
                        </div>
                        <div className="col-12">{renderStatus()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
