import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import CreateBlog from "../pages/CreateBlog";
import GlobalChanges from "../pages/GlobalChanges";
import History from "../pages/History";
import { AuthContext } from "../context/AuthContext";
import Clients from "../pages/Clients";
import Client from "../pages/Client";

export default function AdminRoutes() {
  const auth = useContext(AuthContext);

  const renderPage = (page) => {
    if (!auth.token) return <Navigate to="/" />;
    if (!auth.isAdmin) <Navigate to="/dashboard" />;
    return page;
  };

  return (
    <Routes>
      <Route path="/global-changes" element={renderPage(<GlobalChanges />)} />
      <Route path="/create-blog" element={renderPage(<CreateBlog />)} />
      <Route path="/edit-blog/:slug" element={renderPage(<CreateBlog />)} />
      <Route path="/history" element={renderPage(<History />)} />
      <Route path="/clients" element={renderPage(<Clients />)} /> 
      <Route path="/client/:id" element={renderPage(<Client />)} /> 
    </Routes>
  );
}
