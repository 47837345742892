import { useEffect, useState } from "react";
import styles from "./Popup.module.css";
import axios from "../../axios";

const Popup = (props) => {
  const [status, setStatus] = useState(null);

  const handleSubmit = async () => {
    setStatus("loading");
    try {
      const headers = { Authorization: `Bearer ${props.token}` };
      await axios.delete(`/blog/${props.slug}`, { headers });
      return setStatus("success");
    } catch (err) {
      console.error(err);
      return setStatus("error");
    }
  };
  useEffect(() => {
    if (status === "success") {
      setTimeout(() => {
        props.close();
        window.location = "/blog"
      }, 1000)
    }
  }, [status])

  return (
    <>
      <div
        className={[styles.modal, props.active ? styles.active : ""].join(" ")}
        onClick={props.close}
      />
      <div
        className={[styles.container, props.active ? styles.active : ""].join(
          " ",
        )}
      >
        <h2>Si si istý?</h2>
        <div className={styles.btns}>
          <button className="btn" disabled={status === "loading"} onClick={props.close}>
            Zrušiť
          </button>
          <button disabled={status === "loading"} className="btn btn-danger" onClick={handleSubmit}>
            {status === "loading" ? "Odstraňuje sa..." : "Odstrániť"}
          </button>
        </div>
        {status === "success" && (
          <small className={styles.success}>Príspevok bol úspešne odstránený</small>
        )}
        {status === "error" && (
          <small className={styles.error}>Niečo sa pokazilo</small>
        )}
      </div>
    </>
  );
};

export default Popup;
