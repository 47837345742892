import React, { useContext } from "react";
import { SidebarContext } from "../../context/SidebarContext";

export default function ToggleIcon() {
  const sidebar = useContext(SidebarContext);
  return (
    <div
      className="app-sidebar__toggle"
      data-toggle="sidebar"
      onClick={sidebar.toggle}
    >
      <a className="open-toggle" href="#">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-align-left header-icon mt-1"
        >
          <line x1="17" y1="10" x2="3" y2="10"></line>
          <line x1="21" y1="6" x2="3" y2="6"></line>
          <line x1="21" y1="14" x2="3" y2="14"></line>
          <line x1="17" y1="18" x2="3" y2="18"></line>
        </svg>
      </a>
    </div>
  );
}
