import infoIcon from "../../assets/iconfonts/info-circle.png";
import { useState } from "react";
import styles from "./Popup.module.css";
import axios from "../../axios";

const Popup = (props) => {
  const initialState = { amount: 0, iban: "", wallet: "" }
  const [state, setState] = useState({ ...initialState });
  const [status, setStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");
    try {
      const headers = { Authorization: `Bearer ${props.token}` };
      await axios.post("/user/early-withdrawal", state, { headers });
      setState({ ...initialState })
      return setStatus("success");
    } catch (err) {
      console.error(err);
      return setStatus("error");
    }
  };

  const update = (e) => setState({ ...state, [e.target.id]: e.target.value });

  return (
    <>
      <div
        className={[styles.modal, props.active ? styles.active : ""].join(" ")}
        onClick={props.close}
      />
      <div
        className={[styles.container, props.active ? styles.active : ""].join(
          " ",
        )}
      >
        <h2>Žiadosť o predčastný výber</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.input}>
            <input
              type="text"
              value="Môj kapitál"
              placeholder="Môj kapitál"
              readOnly={true}
            />
            <span className={styles.amount}>{props.amount}</span>
          </div>
          <div>
            <label>Zadať sumu na výber</label>
            <div className={styles.input}>
              <input
                type="number"
                placeholder="Zadajte sumu . . ."
                id="amount"
                value={state.amount}
                onChange={update}
                min={props.max > 0 ? 1 : 0}
                max={props.max}
              />
              <span className={styles.moreInfoCon}>
                <img src={infoIcon} alt="more info" />
                <span className={styles.moreInfo}>
                  Vaša žiadosť bude vybavená do 30 pracovných dní.
                </span>
              </span>
            </div>
          </div>
          <div>
            <label>Crypto wallet</label>
            <div className={styles.input}>
              <input
                type="text"
                placeholder="Zadajte Vašu peňaženku . . ."
                id="wallet"
                value={state.wallet}
                onChange={update}
              />
            </div>
          </div>
          <div>
            <label>IBAN</label>
            <div className={styles.input}>
              <input
                type="text"
                placeholder="Zadajte IBAN . . ."
                id="iban"
                value={state.iban}
                onChange={update}
              />
            </div>
          </div>
          <button disabled={status === "loading"}>
            {status === "loading" ? "Odosiela sa..." : "Potvrdiť výber"}
          </button>
          {status === "success" && (
            <small className={styles.success}>
              Žiadosť bola úspešne odoslaná
            </small>
          )}
          {status === "error" && (
            <small className={styles.error}>Something went wrong</small>
          )}
        </form>
      </div>
    </>
  );
};

export default Popup;
