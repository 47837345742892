import React from "react";

export default function NavIcons() {
  return (
    <div className="sidebar-navs">
      <ul className="nav nav-pills-circle">
        <li
          className="nav-item"
          data-placement="top"
          data-toggle="tooltip"
          title="Odhlásiť sa"
        >
          <a className="icon" href="#">
            <i className="las la-life-ring header-icons"></i>
          </a>
        </li>
        <li
          className="nav-item"
          data-placement="top"
          data-toggle="tooltip"
          title="Dokumenty"
        >
          <a className="icon" href="#">
            <i className="las  la-file-alt header-icons"></i>
          </a>
        </li>
        <li
          className="nav-item"
          data-placement="top"
          data-toggle="tooltip"
          title="Projects"
        >
          <a href="#" className="icon">
            <i className="las la-project-diagram header-icons"></i>
          </a>
        </li>
        <li
          className="nav-item"
          data-placement="top"
          data-toggle="tooltip"
          title="Nastavenia"
        >
          <a className="icon" href="#">
            <i className="las la-cog header-icons"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}
