import { useContext, useState } from "react";
import styles from "./index.module.css";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";

const FinishTrade = (props) => {
  const [state, setState] = useState(null);
  const { token } = useContext(AuthContext)

  const closeTrade = async () => {
    setState("loading");
    try {
      const res = await axios.patch(`/trade/close/${props.trade._id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      props.setUpdateTrade({ ...res.data, currency: props.pair });
    } catch (err) {
      console.log(err);
    }
    setState("success");
  };

  return state ? (
    <div className={[styles.popup, "fix-bg"].join(" ")}>
      <h2 className={["mt-5", styles.title].join(" ")}>ZATVORIŤ OBCHOD</h2>
      <p className="mx-auto" style={{ width: "280px", textAlign: "center" }}>
        Objednávka bude spracovaná do niekoľkých minút po potvrdení
        administrátorom
      </p>
    </div>
  ) : (
    <div
      className={[styles.popup, "fix-bg"].join(" ")}
      style={{ width: "min(90%, 400px)" }}
    >
      <h2 className={styles.title}>ZATVORIŤ OBCHOD</h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <img src={props.trade?.currency?.logo} width="20px" height="20px" />
        <p>{props.trade?.currency?.symbol?.toUpperCase?.()}/USDT</p>
      </div>
      <div className={styles.btn_group}>
        <button
          className={[styles.btn, styles.red].join(" ")}
          onClick={props.close}
        >Zrušiť</button>
        <button
          className={[styles.btn, styles.green].join(" ")}
          style={{ minWidth: "160px" }}
          onClick={closeTrade}
          disabled={state === "loading"}
        >
          {state === "loading" ? "ZATVORIŤ OBCHOD" : "ZATVORIŤ OBCHOD"}
        </button>
      </div>
    </div>
  );
};

export default FinishTrade;
