import axios from "../../axios";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import EChartsReact from "echarts-for-react";
import CoinPair from "./CoinPair";
import styles from "./index.module.css";
import { useState } from "react";

const intervals = [
  "1m",
  "3m",
  "5m",
  "15m",
  "30m",
  "1h",
  "2h",
  "4h",
  "6h",
  "8h",
  "12h",
  "1d",
  "3d",
  "1w",
  "1M",
]

const Chart = (props) => {
  const auth = useContext(AuthContext);
  const [currencies, setCurrencies] = useState(null)
  const [tickInterval, setTickInterval] = useState("1m")
  const [state, setState] = useState(null);

  const getCurrencies = async () => {
    try {
      const res = await axios.get(`/user/currency`, { headers: { Authorization: `Bearer ${auth.token}` } })
      setCurrencies(res.data);
      props.setPair(res.data[0]);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCurrencies();
  }, [])


  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    axisPointer: {
      link: [{ xAxisIndex: "all" }],
    },
    xAxis: {
      data: state?.times?.map?.(i => new Date(i)).map(a => new Intl.DateTimeFormat().format(a)),
      splitLine: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    yAxis: {
      splitLine: {
        show: false,
      },
      scale: true

    },
    grid: {
      left: 0,
    },
    dataZoom: [
      {
        type: "inside",
        minValueSpan: 10,
      },
    ],
    series: [
      {
        type: "candlestick",
        data: state?.prices,
      },
    ],
  };

  const getData = async () => {
    console.log(tickInterval)
    const res = await axios.get(`/user/candle-data?symbol=${props.pair.symbol}&tick_interval=${tickInterval}`, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
    setState(res.data)
  };

  useEffect(() => {
    if (!props.pair) return;
    getData();
    const timer = setInterval(getData, 10 * 1000);
    return () => {
      clearInterval(timer);
    }
  }, [props.pair, tickInterval]);

  if (!state) return;
  return (
    <>
      <header className={styles.header}>
        <div>
          {currencies?.map?.((p) => (
            <CoinPair
              key={p._id}
              active={props?.pair?._id === p?._id}
              select={() => props.setPair(p)}
              logo={p.logo}
              symbol={p.symbol}
            />
          ))}
        </div>
      </header>
      <div className={[styles.chart, "fix-bg"].join(" ")}>
        <EChartsReact
          option={option}
          className="react-charts-custom"
          style={{ height: "600px" }}
        />

        <div className={styles.left_controls}>
          <button className={[styles.btn, styles.blue, "btn-bg-fix"].join(" ")}>
            <span>{props.pair?.symbol?.toUpperCase?.()}/USDT</span>
          </button>

          <button className={[styles.btn, styles.blue, "btn-bg-fix"].join(" ")} style={{ padding: "0" }}>
            <div class="dropdown show">
              <a
                class="dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                style={{ display: "inline-block", width: "100%", textTransform: "none", padding: "13px" }}
                aria-expanded="false"
              >
                {tickInterval}
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {intervals.map(i => (
                  <span
                    class="dropdown-item"
                    role="button"
                    key={i}
                    onClick={() => setTickInterval(i)}
                    style={{ textTransform: "none" }}
                  >
                    {i}
                  </span>
                ))}
              </div>
            </div>
          </button>
        </div>
        <button className={[styles.button, styles.btn, styles.blue, "btn-bg-fix"].join(" ")} onClick={props.openWithdraw}>
          <span>VÝBER</span>
        </button>
      </div>
    </>
  );
};

export default Chart;
