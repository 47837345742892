import React, {useState, useEffect} from "react";
import axios from '../../axios.js';
import QRPopup from './QRPopup.jsx'

export default function TwoFac(props) {
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [url, setUrl] = useState("");

  useEffect(() => {
    setStatus(props.twoFacEnabled)
  }, [props.twoFacEnabled])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors([]);

    try {
      const userData = { status, userId: props.userId };
      const headers = { headers: {Authorization: `Bearer ${props.token}`} }
      const res = await axios.put("/user/change-twofac", userData, headers)
      setUrl(res.data.qr)
      setSuccess(true);
    }catch(err) {
      console.error(err);
    }

    setLoading(false);
  }

  return (
      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Google Authenticator</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="">
                <label className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="example-checkbox2"
                    value={status && "checked"}
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                  <span className="custom-control-label">{props.twoFacEnabled ? "Deaktivovať":"Aktivovať"} Google Authenticator</span>
                </label>
              </div>

              <button
                type="submit"
                className="btn btn-primary mt-4 mb-0"
                disabled={loading}
              >
                {loading ? "Mení sa..." : "Nastaviť"}
              </button>
              {success && (
                <small className="text-success block mt-2">
                  2FA bol úspešne zrušený.
                </small>
              )}
              {errors &&
                errors.map((error, i) => (
                  <small key={`error-${i}`} className="text-danger block mt-2">
                    {error}
                  </small>
                ))}
            </form>
          </div>
        </div>
        <QRPopup url={url} open={!!url} close={() => setUrl("")} />
      </div>
  );
}
