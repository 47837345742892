import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import SwitchCurrency from "./SwitchCurrency";

export default function Menu() {
  const auth = useContext(AuthContext);

  const notAdminLinks = () => {
    if (auth.isAdmin) return;
    return (
      <>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/market">
            <svg
              class="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
            </svg>
            <span className="side-menu__label">Trading</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu "
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/support">
            <svg
              className="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M15.2 18.21c1.21.41 2.48.67 3.8.76v-1.5c-.88-.07-1.75-.22-2.6-.45l-1.2 1.19zM6.54 5h-1.5c.09 1.32.35 2.59.75 3.79l1.2-1.21c-.24-.83-.39-1.7-.45-2.58zM14 8h5V5h-5z"
                opacity=".3"
              />
              <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h12v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" />
            </svg>
            <span className="side-menu__label">Podpora</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu "
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
      </>
    );
  };

  const adminLinks = () => {
    if (!auth.isAdmin) return;
    return (
      <>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/clients">
            <svg
              class="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
            </svg>
            <span className="side-menu__label">Trading</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu "
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/global-changes">
            <svg
              className="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            <span className="side-menu__label">Globálne úpravy</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu "
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/history">
            <svg
              className="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            <span className="side-menu__label">Všetky zmeny</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu"
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/create-blog">
            <svg
              className="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M16.66 4.52l2.83 2.83-2.83 2.83-2.83-2.83 2.83-2.83M9 5v4H5V5h4m10 10v4h-4v-4h4M9 15v4H5v-4h4m7.66-13.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65zM11 3H3v8h8V3zm10 10h-8v8h8v-8zm-10 0H3v8h8v-8z"
              /></svg
            >
            <span className="side-menu__label">Vytvoriť blog</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu"
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
      </>
    );
  };
  return (
    <>
      <SwitchCurrency />

      <li className="">
        <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/dashboard">
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
          </svg>
          <span className="side-menu__label">Dashboard</span>
          <i className="angle fa fa-angle-right"></i>
        </NavLink>

        <ul
          className="slide-menu "
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>

      {!auth.isSeller && <li className="">
        <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/wallet">
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M14.25 2.26l-.08-.04-.01.02C13.46 2.09 12.74 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-4.75-3.31-8.72-7.75-9.74zM19.41 9h-7.99l2.71-4.7c2.4.66 4.35 2.42 5.28 4.7zM13.1 4.08L10.27 9l-1.15 2L6.4 6.3C7.84 4.88 9.82 4 12 4c.37 0 .74.03 1.1.08zM5.7 7.09L8.54 12l1.15 2H4.26C4.1 13.36 4 12.69 4 12c0-1.85.64-3.55 1.7-4.91zM4.59 15h7.98l-2.71 4.7c-2.4-.67-4.34-2.42-5.27-4.7zm6.31 4.91L14.89 13l2.72 4.7C16.16 19.12 14.18 20 12 20c-.38 0-.74-.04-1.1-.09zm7.4-3l-4-6.91h5.43c.17.64.27 1.31.27 2 0 1.85-.64 3.55-1.7 4.91z"
            /></svg
          >
          <span className="side-menu__label">Wallet</span>
          <i className="angle fa fa-angle-right"></i>
        </NavLink>
        <ul
          className="slide-menu "
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>}

      <li className="">
        <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/user-profile">
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
          </svg>
          <span className="side-menu__label">Môj profil</span>
          <i className="angle fa fa-angle-right"></i>
        </NavLink>
        <ul
          className="slide-menu "
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>


      {adminLinks()}
      {notAdminLinks()}


      <li className="">
        <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/blog">
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M15.2 18.21c1.21.41 2.48.67 3.8.76v-1.5c-.88-.07-1.75-.22-2.6-.45l-1.2 1.19zM6.54 5h-1.5c.09 1.32.35 2.59.75 3.79l1.2-1.21c-.24-.83-.39-1.7-.45-2.58zM14 8h5V5h-5z"
              opacity=".3"
            />
            <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h12v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" />
          </svg>
          <span className="side-menu__label">Blog</span>
          <i className="angle fa fa-angle-right"></i>
        </NavLink>
        <ul
          className="slide-menu "
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>

      <li className="">
        <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/partners">
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M15.2 18.21c1.21.41 2.48.67 3.8.76v-1.5c-.88-.07-1.75-.22-2.6-.45l-1.2 1.19zM6.54 5h-1.5c.09 1.32.35 2.59.75 3.79l1.2-1.21c-.24-.83-.39-1.7-.45-2.58zM14 8h5V5h-5z"
              opacity=".3"
            />
            <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h12v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" />
          </svg>
          <span className="side-menu__label">Partneri</span>
          <i className="angle fa fa-angle-right"></i>
        </NavLink>
        <ul
          className="slide-menu "
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>
      <li className="">
        <a
          className="side-menu__item"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            auth.logout();
          }}
        >
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <span className="side-menu__label">Odhlásiť sa</span>
          <i className="angle fa fa-angle-right"></i>
        </a>
        <ul
          className="slide-menu"
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>
    </>
  );
}
