import React, { useState } from "react";
import useScripts from "../../hooks/useScripts";
import axios from "../../axios";
import { Link } from "react-router-dom";
import styles from './index.module.css'


export default function SignUp() {
  useScripts([], true);

  const initialState = {
    fname: { value: "", error: "" },
    lname: { value: "", error: "" },
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
  };

  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const clearErrors = () => {
    const updatedState = { ...state };
    for (const item in updatedState) {
      updatedState[item].error = "";
    }

    setState(updatedState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    setSuccess(false);
    clearErrors();

    if (state.password.value !== state.confirmPassword.value) {
      const updatedState = { ...state };
      updatedState.confirmPassword.error = "Heslá sa nezhodujú";
      setState(updatedState);
      setLoading(false);
      return;
    }

    try {
      const userData = {};
      for (const item in state) {
        if (item === "confirmPassword") continue;
        userData[item] = state[item].value;
      }
      await axios.post(`/user/signup`, userData);
      setSuccess(true);
      setState({ ...initialState })
    } catch (err) {
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Niečo sa pokazilo"]);
      }
    }

    setLoading(false);
  };

  const renderErrors = () =>
    errors.map((error) => (
      <small
        key={error}
        className="text-capitalize text-danger block mt-1 text-center"
      >
        {error}
      </small>
    ));

  const renderStatus = () => {
    if (success)
      return (
        <small className="text-capitalize text-success block mt-1 text-center">
          Na Váš email bol zaslaný verifikačný link.
        </small>
      );

    return renderErrors();
  };

  return (
    <div className="page login-container">
      <div className="page-single" >
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="card"
                    style={{
                      boxShadow:
                        "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0) 0px -3px 0px inset",
                      backgroundColor: "#062d51",
                      borderRadius: "25px",
                      border: "1px solid rgb(0 0 0)",
                    }}
                  >
                    <div className={`card-body ${styles.signupForm}`} >
                      <div className="text-center title-style mb-6">
                        <p
                          className="mb-2"
                          style={{
                            textAlign: "center",
                            color: "#ffffff",
                            position: "relative",
                            bottom: "10px",
                            fontWeight: "bold",
                            fontSize: "18px",
                            pointerEvents: "none",
                          }}
                        >
                          Registrácia
                        </p>
                      </div>

                      <div className="input-group">
                        <div className={`input-group-prepend ${styles.inputIcon}`}>
                          <div className="input-group-text">
                            <i className="fe fe-user" style={{color: "white"}}></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Meno"
                          value={state.fname.value}
                          onChange={(e) => handleInput("fname", e.target.value)}
                        />
                      </div>
                      <small className="text-danger block mb-4 mt-1">
                        {state.fname.error}
                      </small>

                      <div className="input-group">
                        <div className={`input-group-prepend ${styles.inputIcon}`}>
                          <div className="input-group-text">
                            <i className="fe fe-user" style={{color: "white"}}></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Priezvisko"
                          value={state.lname.value}
                          onChange={(e) => handleInput("lname", e.target.value)}
                        />
                      </div>
                      <small className="text-danger block mb-4 mt-1">
                        {state.lname.error}
                      </small>

                      <div className="input-group">
                        <div className={`input-group-prepend ${styles.inputIcon}`}>
                          <div className="input-group-text">
                            <i className="fe fe-mail" style={{color: "white"}}></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          value={state.email.value}
                          onChange={(e) => handleInput("email", e.target.value)}
                        />
                      </div>
                      <small className="text-danger block mb-4 mt-1">
                        {state.email.error}
                      </small>

                      <div className="input-group">
                        <div className={`input-group-prepend ${styles.inputIcon}`}>
                          <div className="input-group-text">
                            <i className="fe fe-lock" style={{color: "white"}}></i>
                          </div>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Heslo"
                          value={state.password.value}
                          onChange={(e) =>
                            handleInput("password", e.target.value)
                          }
                        />
                      </div>
                      <small className="text-danger block mb-4 mt-1">
                        {state.password.error}
                      </small>

                      <div className="input-group">
                        <div className={`input-group-prepend ${styles.inputIcon}`}>
                          <div className="input-group-text">
                            <i className="fe fe-lock" style={{color: "white"}}></i>
                          </div>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Potvrdiť heslo"
                          value={state.confirmPassword.value}
                          onChange={(e) =>
                            handleInput("confirmPassword", e.target.value)
                          }
                        />
                      </div>
                      <small className="text-danger block mb-4 mt-1">
                        {state.confirmPassword.error}
                      </small>

                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn  btn-primary btn-block px-4"
                            disabled={loading}
                            onClick={handleSubmit}
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "#0a4b70",
                              borderColor: "#000",
                              boxShadow: "0 0 10px -5px rgb(0 0 0)",
                            }}
                          >
                            {loading
                              ? "Vytváranie používateľa"
                              : `Vytvoriť účet`}
                          </button>
                        </div>
                        <div className="col-12">{renderStatus()}</div>
                      </div>
                      <Link
                        to="/"
                        style={{
                          display: "block",
                          textAlign: "center",
                          marginTop: "1rem",
                          color: "white"
                        }}
                      >Prihlásiť sa </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
