import { useContext, useEffect, useState } from "react";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import { AuthContext } from "../../context/AuthContext";
import axios from "../../axios";

const green = "#40A099";
const red = "#A04040";

const format = (more) => new Intl.NumberFormat('sk-SK', {
  style: "currency",
  currency: "EUR",
  ...more
}).format;


const options = {
  year: 'numeric', // Display the full numeric year
  month: '2-digit', // Display the month with leading zeros (01-12)
  day: '2-digit',  // Display the day with leading zeros (01-31)
  hour: 'numeric', // Display the hour (24-hour format)
  minute: '2-digit', // Display the minute with leading zeros (00-59)
  // You can optionally include seconds: second: '2-digit'
};

const tFormat = (new Intl.DateTimeFormat('sk-SK', options)).format;

const Trade = (props) => {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  useCloseSidebar();

  const getTrades = async (init) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.get(`/trade/${props.id}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      const c = res.data?.trades || [];

      if (init) {
        initDatatable(c);
      } else {
        window.$('#my-trades').dataTable().fnClearTable();
        window.$('#my-trades').dataTable().fnAddData(c);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTrades(true);
    const timer = setInterval(getTrades, 10 * 1000);
    return () => {
      clearInterval((timer));
    }
  }, [])

  const initDatatable = (trades) => {
    console.log(trades)
    window.$("#my-trades").DataTable({
      lengthChange: false,
      language: {
        searchPlaceholder: "Vyhľadávanie obchodu",
        sSearch: "",
        lengthMenu: "_MENU_ ",
      },
      order: [[2, "desc"]],
      data: trades,
      columns: [
        {
          data: "type",
          render: function(_, __, r) {
            const style = `color: ${r.type === 'buy' ? green : red};`;
            return `<span style="${style}">${r.type.toUpperCase()}</span>`
          }
        },
        {
          data: "currency",
          render: function(_, __, r) {
            return `${r.currency?.symbol?.toUpperCase?.()}/USDT`;
          }
        },
        {
          data: "amount",
          render: function(_, __, r) {
            return `<span style="color: ${green}">${format()(r.amount)}</span>`
          }
        },
        {
          data: "result",
          render: function(_, __, r) {
            const symbol = r.result - r.fee > 0 ? "+" : "-";
            const pl = Math.max(r.result - r.fee, -r.amount + r.fee);
            return `<span style="color: ${r.result - r.fee > 0 ? green : red}">${symbol}${format()(pl)}</span>`
          }
        },
        {
          data: "leverage",
          render: function(_, __, r) {
            return `1:${r.leverage}`
          }
        },
        {
          data: "entry",
          render: function(_, __, r) {
            return `<span class="text-gray">${r.entry}</span>`
          }
        },
        {
          data: "createdAt",
          render: function(_, __, r) {
            return `<span class="text-gray">${tFormat(new Date(r.createdAt))}</span>`
          }
        },
        {
          data: "exit",
          render: function(_, __, r) {
            return `<span class="text-gray">${r.exit && r.status === "closed" ? r.exit : "-"}</span>`
          }
        },
        {
          data: "dateOfExit",
          render: function(_, __, r) {
            return `<span class="text-gray">${r.dateOfExit ? tFormat(new Date(r.dateOfExit)) : "-"}</span>`
          }
        },
        {
          data: "fee",
          render: function(_, __, r) {
            return format()(r.fee);
          }
        },
        {
          data: "status",
          render: function(_, __, r) {
            let sColor = green;
            if (r.status === "closed") sColor = "#777777";

            const style = `color: ${sColor}; text-transform: capitalize`;
            return `<span style="${style}">${r.status === "closed" ? "Uzatvorené" : "Otvorené"}</span>`
          }
        },
      ]
    });
  };

  return (
    <>
      <div className="card" style={{ maxWidth: "1300px", marginTop: "30px" }}>
        <div className="card-header">
          <div className="card-title" style={{ textTransform: "none" }}>Obchody klienta</div>
        </div>
        <div className="card-body" style={{ marginTop: "0" }}>
          <div className="">
            <div className="table-responsive">
              <table
                id="my-trades"
                className="table table-bordered text-nowrap key-buttons"
              >
                <thead>
                  <tr style={{ color: "#fff" }}>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">BUY/SELL</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">Pár</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">Vklad</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">ZISK/STRATA</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">Páka</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">Vstup</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">Dátum vstupu</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">Výstup</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">DÁTUM VÝSTUPU</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">Poplatok</th>
                    <th style={{ color: "#8A8A8A" }} className="border-bottom-0">Stav</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      {/* {edit && <Edit onUpdate={handleUpdate} edit={edit} close={() => setEdit(null)} />} */}
    </>
  )
}

export default Trade
