import { createContext, useState } from "react";
import jwtDecode from "jwt-decode";
import AdminSpecialPassword from "../components/AdminSpecialPassword";

const AuthContext = createContext();

const AuthState = (props) => {
  const prevToken = localStorage.getItem("authToken");
  const prevSpecialToken = localStorage.getItem("prevSpecialToken");
  const [token, setToken] = useState(prevToken);
  let decoded = {};
  if (token) decoded = jwtDecode(token);

  const [isAdmin, setIsAdmin] = useState(decoded.isAdmin);
  const [isSeller, setIsSeller] = useState(decoded.isSeller);
  const [userId, setUserId] = useState(decoded.id);
  const [specialToken, setSpecialToken] = useState(prevSpecialToken);
  const [specialPopup, setSpecialPopup] = useState(false);

  const state = {
    token,
    isAdmin,
    isSeller,
    userId,
    specialToken,
    setSpecialPopup,
    login: (value) => {
      const decoded = jwtDecode(value);
      setIsAdmin(decoded.isAdmin);
      setIsSeller(decoded.isSeller);
      setUserId(decoded.id);
      setToken(value);
      localStorage.setItem("authToken", value);
    },
    getToken: () => token,
    logout: () => {
      setToken(null);
      setUserId(null);
      setIsAdmin(null);
      setIsSeller(null);
      localStorage.removeItem("authToken");
      localStorage.removeItem("prevSpecialToken");
      sessionStorage.removeItem("show-special-password");
    },
    updateSpecialToken: (token) => {
      setSpecialToken(token)
      localStorage.setItem("prevSpecialToken", token);
      setSpecialPopup(false);
    },
  };

  return (
    <AuthContext.Provider value={state}>
      {props.children}
      <AdminSpecialPassword open={specialPopup} close={() => setSpecialPopup(false)} />
    </AuthContext.Provider>
  );
};

export { AuthState, AuthContext };
