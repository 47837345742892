import styles from './info.module.css'

const format = (more) => new Intl.NumberFormat('sk-SK', {
  style: "currency",
  currency: "EUR",
  ...more
}).format;

const Card = (props) => {
  return (
    <div className={[styles.card, "fix-bg"].join(" ")}>
      <h2>{props.title}</h2>
      <h3>{format()(props.amount)}</h3>
    </div>
  )
}

export default Card
