import moment from "moment";
import "moment/locale/sk";
moment.locale("sk");

const findDiff = (item1, item2, interval) => {
  // HERE we are assuming that all the months are different.
  const setDateToZero = (date) => {
    const arr = date.split("-");
    arr.splice(2, 1, "01");
    return arr.join("-");
  };
  const d1 = setDateToZero(item1);
  const d2 = setDateToZero(item2);

  const diff = moment(d1).diff(moment(d2));

  if (interval === "days") return moment.duration(diff).asDays();
  else if (interval === "years") return moment.duration(diff).asYears();
  else if (interval === "months") return moment.duration(diff).asMonths();
};

/**
 * in case we have capital which at furthur dates and profits which are behind
 * then we want to add profit till that latest date of capital.
 **/
const fillBeforeLatest = (
  result,
  latestCapitalDate,
  latestProfitDate,
  interval
) => {
  if (!latestProfitDate) return;
  const diff = Math.round(
    findDiff(latestCapitalDate, latestProfitDate, interval)
  );
  for (let i = 0; i < diff; i++) {
    const item = { ...result[0] };
    item.dateCreated = moment(item.dateCreated)
      .add(1, interval)
      .format("YYYY-MM-DD");
    result.unshift(item);
  }
};

const fillGaps = (result, interval) => {
  let currIndex = result.length - 1;
  if (currIndex <= 1) return;
  for (let i = 0; i < currIndex; i++) {
    const curr = result[i];
    const prev = result[i + 1];
    const diff = Math.round(
      findDiff(curr.dateCreated, prev.dateCreated, interval)
    );

    if (diff <= 1) continue;

    for (let j = 0; j < diff - 1; j++) {
      const item = { ...prev };
      item.dateCreated = moment(prev.dateCreated)
        .add(1, interval)
        .format("YYYY-MM-DD");
      currIndex++;
      result.splice(i + 1, 0, item);
    }
  }
};

/**
 * @data -  All transactions (desending order).
 * forLast - Data for last 12 months or so (desending order).
 * interval - if it's for months or years.
 * limit -  the max limit (index) of array so if it's for 12 month limit will be 11
 **/
// in case there are some data before the last one fill it
const fillWithBeforeLast = (data, forLast, interval, limit) => {
  let currIndex = forLast.length - 1;

  const getBeforeLast = (last) => {
    for (const item of data) {
      const asDiff = findDiff(item.dateCreated, last.dateCreated, interval);
      if (asDiff <= -1) return item;
    }
    return null;
  };

  // this is in case we have less then 12 months in our data and we have other data that are before 1 year.
  // So we are gonna grat the last availalble  data and then fill the remining ones with that.
  if (currIndex < limit) {
    const beforeLastItem = getBeforeLast(forLast[currIndex]);
    if (!beforeLastItem) return;
    let index = 1;
    const lastRealItem = forLast[currIndex];

    while (currIndex < limit) {
      currIndex++;
      const item = { ...beforeLastItem };
      item.dateCreated = moment(lastRealItem.dateCreated)
        .subtract(index, interval)
        .format("YYYY-MM-DD");
      forLast[currIndex] = item;
      index++;
    }
  } else {
    // if it has more than max values then remove them.  
    forLast.splice(limit + 1);
  }
};

/**
 * When we add global profit we create profit transaction for each user.
 * This function will Merge the Global profits from each user into 1 transaction by adding all those whose id matches.
 * @result - Array of transactions - Each item's amount is calcuated using previous item's amount and type.
 * i.e in case it's spending type then subtract otherwise add.
 * @item - A transaction item
 */
function mergeGlobal(result, item) {
  // if it's not a global (profit) transaction then just return false.
  let present = false;
  if (item.globalId) {
    for (const gi of result) {
      if (gi.globalId === item.globalId) {
        gi.amount += item.amount;
        present = true;
        break;
      }
    }
  }
  return present;
}

/**
 * Get all the profits (transactions)
 * @data is an array of all transactions sorted in ascending order
 *  return - Return an array of profits in desending order.
 **/
const getProfit = (data) => {
  const result = [];

  // loop through all the transactions
  for (const item of data) {
    let lastIndex = result.length;
    if (item.transType === "profit") {
      let present = mergeGlobal(result, item);

      // if it's not a globalProfit then add the item to result.
      if (!present) {
        result.push({ ...item });

        // if it's not the first item then set the amount to
        // prev item amount + current item amount;
        if (lastIndex !== 0)
          result[lastIndex].amount += result[lastIndex - 1].amount;
      }
    } else if (
      item.transType === "withdrawal" &&
      item.withdrawType === "from-profit"
    ) {
      result.push({ ...item });
      // if it's not the first item then set the amount to
      // prev item amount - current item amount;
      if (lastIndex !== 0)
        result[lastIndex].amount =
          result[lastIndex - 1].amount - result[lastIndex].amount;
    }
  }
  return result.reverse();
};

// get all the capitals (transactions)
const getCapital = (data) => {
  const result = [];

  for (let i = 0; i < data.length; i++) {
    const item = { ...data[i] };
    let lastIndex = result.length;
    if (item.transType === "withdrawal") {
      result.push(item);
      result[lastIndex].amount =
        result[lastIndex - 1].amount - result[lastIndex].amount;
    } else if (item.transType === "deposit") {
      result.push(item);
      if (lastIndex !== 0) {
        result[lastIndex].amount =
          result[lastIndex - 1].amount + result[lastIndex].amount;
      }
    } else if (item.transType === "profit") {
      let present = mergeGlobal(result, item);

      if (!present) {
        result.push(item);
        if (lastIndex !== 0) {
          result[lastIndex].amount =
            result[lastIndex - 1].amount + result[lastIndex].amount;
        }
      }
    }
  }

  return result.reverse();
};

/**
 * Basically it strips duplicate transactions in a month or year.
 * @data - Array of transaction either profit or capital.
 * Transactions are calcuated so that all transactions depends on previous ones.
 * It's in desending order. Or recent date at first.
 * @format - Symbol if it's montly 'M' otherwise 'YYYY'
 * @firstDate - Latest month or year after which we don't have any more data.
 * it's gonna be a number like 7 for july, or  2022
 * This helps us reduce the number of calculation we need to perform.
 * For example if it's july then we only need to calculate from aug of prev year to this july
 * @type - if it's months or years.
 * @dateLimit -  if it's months then limit is gonna be 12 months. For years it's 10
 * @return - an array of transaction within the given limit and
 * also the index upto which we have data.
 **/
const stripExtra = (data, format, firstDate, type, dateLimit) => {
  const result = [];
  let index = 0;
  let currentDate = firstDate;
  const firstItemDate = moment(data[0]?.dateCreated);

  for (const item of data) {
    const itemDate = parseInt(moment(item.dateCreated).format(format));
    const currItemDate = moment(item.dateCreated);

    // find the difference between the latest transaction and current transaction.
    const diff = moment.duration(firstItemDate.diff(currItemDate));
    let diffDate;
    if (type === "days") {
      diffDate = diff.asDays();
    } else if (type === "months") {
      diffDate = diff.asMonths();
    } else if (type === "years") {
      diffDate = diff.asYears();
    }

    // if it's over the limit then break;
    if (diffDate >= dateLimit) {
      break;
    }

    // it's different month/year then update the index and  update currentDate to itemDate.
    if (itemDate !== currentDate) {
      currentDate = itemDate;
      index++;
    }

    // if we already have an item at this index then just move on.
    if (result[index]) continue;
    // otherwise add it to the result.
    result[index] = { ...item };
  }
  return result;
};

export {
  stripExtra,
  fillBeforeLatest,
  fillGaps,
  fillWithBeforeLast,
  getProfit,
  getCapital,
};
