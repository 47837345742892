import React, { useState } from "react";
import axios from "../../axios.js";
import Switch from "./Switch";

export default function MonthlyProfit(props) {
  const [enabled, setEnabled] = useState(props.enabled || false);
  const [rate, setRate] = useState(props.value || 0);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors([]);

    try {
      const userData = { enabled, rate, account: props.account };
      const headers = { headers: { Authorization: `Bearer ${props.token}`, "special-token": `Bearer ${props.specialToken}` } };
      await axios.put("/admin/monthly-profit", userData, headers);
      setSuccess(true);
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const msg = [];
        fieldErrors.forEach((fieldError) => {
          msg.push(fieldError.msg);
        });
        setErrors(msg);
      } else {
        setErrors("Niečo sa pokazilo");
      }
    }

    setLoading(false);
  };

  return (
    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Aktivujte automatické pridávanie profitu</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="form-label">Mesačný profit v %</label>
              <input
                type="number"
                step="0.01"
                className="form-control"
                value={rate}
                onChange={(e) => setRate(e.target.value)}
              />
            </div>
            <div className="form-group">
              <Switch onClick={() => setEnabled(!enabled)} on={enabled} />
            </div>

            <button
              type="submit"
              className="btn btn-primary mt-4 mb-0"
              disabled={loading}
            >
              {loading ? "Mení sa..." : "Nastaviť"}
            </button>
            {success && (
              <small className="text-success block mt-2">
                Successfully updated.
              </small>
            )}
            {errors &&
              errors.map((error, i) => (
                <small key={`error-${i}`} className="text-danger block mt-2">
                  {error}
                </small>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
}
