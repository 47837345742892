import React from "react";

export default function UserIcon() {
  return (
    <a href="#" className="nav-link pr-0 leading-none" data-toggle="dropdown">
      <span>
        <img
          src="../assets/images/users/2.jpg"
          alt="img"
          className="avatar avatar-md brround"
        />
      </span>
    </a>
  );
}
