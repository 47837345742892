import axios from "../../axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [status, setStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);


  const handleSubmit = async () => {
    setStatus("loading");
    setErrors([]);

    try {
      await axios.post(`/user/forgot-password`, { email });
      setEmail("");
      return setStatus("success");
    } catch (err) {
      setErrors(["Niečo sa pokazilo"]);
    }

    setStatus(null);
  };

  const renderErrors = () =>
    errors.map((error) => (
      <small key={error} className="text-danger block mt-1 text-center">
        {error}
      </small>
    ));

  const renderStatus = () => {
    if (status === "success")
      return (
        <small className="text-capitalize text-success block mt-3 text-center">
          Na Váš email bol zaslaný verifikačný link.
        </small>
      );

    return renderErrors();
  };

  return (
    <div className="page login-container">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div
                    className="card"
                    style={{
                      boxShadow:
                        "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0) 0px -3px 0px inset",
                      backgroundColor: "#062d51",
                      borderRadius: "25px",
                      border: "1px solid rgb(0 0 0)",
                    }}
                  >
                    <div className="card-body">
                      <p
                        style={{
                          textAlign: "center",
                          color: "#ffffff",
                          position: "relative",
                          bottom: "0px",
                          fontWeight: "bold",
                          fontSize: "18px",
                          pointerEvents: "none",
                        }}
                      >
                        Zabudnuté heslo
                      </p>
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{
                              backgroundColor: "#062d51",
                              border: "none"
                            }}
                          >
                            <i
                              className="fe fe-mail"
                              style={{ color: "#fff" }}
                            ></i>
                          </div>
                        </div>
                        <input
                          className="form-control"
                          placeholder="Užívateľský email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          type="text"
                          style={{
                            backgroundColor: "#072d51",
                            border: "1px solid rgb(21 23 26)",
                            borderRadius: "10px",
                            boxShadow:
                              "inset rgb(0 0 0 / 30%) 2px 2px 2.6px",
                          }}
                        />
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn  btn-primary btn-block px-4"
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "#0a4b70",
                              borderColor: "#000",
                              boxShadow: "0 0 10px -5px rgb(0 0 0)",
                            }}
                            onClick={handleSubmit}
                            disabled={status === "loading"}
                          >
                            <i className="fe fe-send"></i> {status === "loading" ? "Odosiela sa..." : "Odoslať"}
                          </button>
                        </div>

                        <div className="col-12">{renderStatus()}</div>
                      </div>
                      <div className="text-center pt-4">
                        <div
                          className="font-weight-normal fs-16"
                          style={{ color: "#fff" }}
                        >
                          <Link
                            to="/"
                            className="btn-link font-weight-normal"
                            style={{
                              color: "#fff",
                              textDecoration: "none",
                              fontSize: "13px",
                            }}
                          >
                            Prihláste sa tu
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
