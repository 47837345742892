import React, { useEffect, useState, useContext } from "react";
import Layout from "../../components/Layout.jsx";
import Loader from "../../components/Loader.jsx";
import PageNav from "../../components/PageNav.jsx";
import axios from "../../axios.js";
import { AuthContext } from "../../context/AuthContext.js";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import useScripts from "../../hooks/useScripts.jsx";
import Row from "./Row.jsx";

const scripts = [
  "../assets/plugins/datatable/js/jquery.dataTables.js",
  "../assets/plugins/datatable/js/dataTables.bootstrap4.js",
  "../assets/plugins/datatable/dataTables.responsive.min.js",
  "../assets/plugins/datatable/responsive.bootstrap4.min.js",
];

export default function Clients() {
  const [loadingScript] = useScripts(scripts, true);
  const auth = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  useCloseSidebar();

  const getClients = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/admin/trading-clients`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      let c = res.data || [];
      c = c.filter(i => !i.isSeller);
      c = [...c.filter(i => i.tradeStatus === "ok"), ...c.filter(i => i.tradeStatus === "pending"), ...c.filter(i => !i.tradeStatus)]

      setClients(c);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
    setTimeout(() => initDatatable(), 500);
  };

  const initDatatable = () => {
    window.$("#clients-all").DataTable({
      lengthChange: false,
      order: false,
      language: {
        searchPlaceholder: "Vyhľadávanie klienta",
        sSearch: "",
        lengthMenu: "_MENU_ ",
      },
    });
  };

  useEffect(() => {
    if (!loadingScript) {
      getClients();
      initDatatable();
    }
  }, [loadingScript]);

  const renderItems = () => {
    if (!clients.length) return null;

    const items = clients.map((item) => {
      const { fname, lname, free_capital, in_trade, tradeStatus, id } = item;
      return (
        <Row
          key={id}
          id={id}
          name={`${fname} ${lname}`}
          deposit={free_capital + in_trade}
          tradeStatus={tradeStatus}
        />
      );
    });
    return items;
  };

  if (loadingScript || loading) {
    return <Loader />;
  }

  return (
    <Layout pageNav={<PageNav />}>
      <div className="card" style={{ maxWidth: "900px" }}>
        <div className="card-header">
          <div className="card-title" style={{ textTransform: "none" }}>
            Všetci klienti
          </div>
        </div>
        <div className="card-body" style={{ marginTop: "0" }}>
          <div className="">
            <div className="table-responsive">
              <table
                id="clients-all"
                className="table table-bordered text-nowrap key-buttons"
              >
                <thead>
                  <tr>
                    <th style={{ color: "#d5dae2" }} className="border-bottom-0">Názov klienta</th>
                    <th style={{ color: "#d5dae2" }} className="border-bottom-0">Vklad</th>
                    <th style={{ color: "#d5dae2" }} className="border-bottom-0">Zmena</th>
                    <th style={{ color: "#d5dae2" }} className="border-bottom-0"></th>
                  </tr>
                </thead>
                <tbody>{renderItems()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </Layout>
  );
}
