import { useContext, useState } from 'react';
import '../pages/UserProfile/QRPopup.css'
import axios from '../axios.js'
import { AuthContext } from '../context/AuthContext';

const AdminSpecialPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [password, setPassword] = useState('');
  const { token, updateSpecialToken } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setSuccess(false);
    setError('');
    try {
      const headers = { headers: { Authorization: `Bearer ${token}` } };
      const res = await axios.post(`/admin/special-password`, { password }, headers);
      setPassword("")
      setSuccess(true);
      updateSpecialToken(res?.data?.token)
      setTimeout(() => props.close(), 1000);
    } catch (err) {
      console.log(err);
      const msg = err?.response?.data?.messages || 'Something went wrong';
      setError(msg)
    }
    setLoading(false)
  };

  return (
    <>
      <div
        className={`${props.open && "open"} qr-modal`}
        onClick={props.close}
        style={{ zIndex: props.open ? Number.MAX_SAFE_INTEGER - 1 : Number.MIN_SAFE_INTEGER }}
      ></div>
      <div
        className={`${props.open && "open"} qr-container`}
        style={{ height: "auto", width: "min(400px, 95vw)", zIndex: props.open ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER }}
      >
        <h2 style={{ marginBottom: "3rem" }}>Bezpečnostné heslo</h2>

        <form onSubmit={handleSubmit}>
          <label htmlFor="adminSpecialPassword" className="form-label">
            Heslo
          </label>
          <input
            type="password"
            className="form-control"
            style={{ background: 'white', borderColor: "#333", color: "#333" }}
            id="adminSpecialPassword"
            placeholder="Zadajte heslo"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
            <button type="button" className="btn btn-secondary" onClick={props.close}>
              Zrušiť
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Overujem . . ." : "Potvrdiť"}
            </button>{" "}
          </div>
        </form>

        {error && <small className="text-danger block mt-3">{error}</small>}
        {success && <small className="text-success block mt-3">Úspešne získané oprávnenia</small>}
      </div>
    </>
  )
}

export default AdminSpecialPassword
