import styles from './index.module.css'

const CoinPair = ({ active, logo, symbol, select }) => {
  return (
    <div className={[styles.coinPair, "fix-bg", active ? "active" : ""].join(" ")} onClick={select}>
      <img src={logo} alt={symbol} />
      <span>{symbol}/USDT</span>
    </div>
  )
}

export default CoinPair
