import { useContext, useEffect, useState } from "react";
import Card from "./Card";
import Switch from "./Switch";
import styles from './info.module.css'
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";

const Info = (props) => {
  const info = props.info;
  const [withdraw, setWithdraw] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [status, setStatus] = useState(null);
  const { token, specialToken } = useContext(AuthContext)


  const handleSubmit = async e => {
    e.preventDefault();
    setStatus("loading")
    try {
      await axios.patch(`/admin/wallet/${props.id}`, { withdraw, deposit }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "special-token": `Bearer ${specialToken}`
        }
      })

      setStatus("success")
    } catch (err) {
      console.error(err?.response?.data);
      const msg = err?.response?.data?.error || "Vyskytla sa neznáma chyba";
      setStatus("error:" + msg)
    }
  }

  useEffect(() => {
    if (withdraw > 0 && deposit !== 0)
      setDeposit(0)
  }, [withdraw])

  useEffect(() => {
    if (deposit > 0 && withdraw !== 0)
      setWithdraw(0)
  }, [deposit])

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.form_control}>
          <label>Vklad</label>
          <input className="fix-bg" type="number" placeholder="Vyberte vklad pre klienta" value={deposit} onChange={e => setDeposit(e.target.valueAsNumber)} />
        </div>
        <div className={styles.form_control}>
          <label>Výber</label>
          <input className="fix-bg" type="number" placeholder="Vyberte stiahnutie klienta" value={withdraw} onChange={e => setWithdraw(e.target.valueAsNumber)} />
        </div>
        <button className={styles.save} disabled={status === "loading"}>{status === "loading" ? "Úspora..." : "Uložiť"}</button>

        {status?.startsWith?.("error:") && <small className="text-danger">{status.split("error:")[1]}</small>}
        {status === "success" && <small className="text-success">Úspešne aktualizované používateľské konto</small>}
      </form>
      <div className={styles.cards}>
        <Card title="Kapitál klienta" amount={info?.in_trade + info?.free_capital} />
        <Card title="V obchodovaní" amount={info?.in_trade} />
        <Card title="Voľný kapitál" amount={info?.free_capital} />
        <Switch id={props.id} />
      </div>
    </div>
  )
}

export default Info
