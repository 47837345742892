import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import PageNav from "../../components/PageNav";
import Info from "./Info";
import Trade from "./Trade";
import useScripts from "../../hooks/useScripts";
import Loader from "../../components/Loader";
import { useContext, useEffect, useState } from "react";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";
import './fix-bg.css'

const scripts = [
  "../assets/plugins/datatable/js/jquery.dataTables.js",
  "../assets/plugins/datatable/js/dataTables.bootstrap4.js",
  "../assets/plugins/datatable/dataTables.responsive.min.js",
  "../assets/plugins/datatable/responsive.bootstrap4.min.js",
];


const Client = () => {
  const { id } = useParams();
  const [loadingScript] = useScripts(scripts, true);
  const [info, setInfo] = useState(null)
  const { token } = useContext(AuthContext)

  const getWalletInfo = async () => {
    try {
      const res = await axios.get(`/user/wallet/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setInfo(res.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getWalletInfo();
  }, [id])


  if (loadingScript) return <Loader />;
  return (
    <Layout pageNav={<PageNav title={info?.name} subtext="Trading" />}>
      <Info id={id} info={info} />
      <Trade id={id} />
    </Layout>

  )
}

export default Client
