import React from "react";

export default function MyFooter() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-md-12 col-sm-12 text-center">
            Copyright © {new Date().getFullYear()}{" "}
            <a href="https://eostrategy.zone" target="_blank">
              EOStrategy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
