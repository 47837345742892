import moment from 'moment';

const Row = (props) => {
  const { transType } = props;

  let color;
  let bg;
  let symbol;
  switch (transType) {
    case "deposit":
      color = "#816ed9";
      bg = "#c9c0f4";
      symbol = "+"
      break;
    case "withdrawal":
      color = "#fdbf01";
      bg = "#fce7a7"
      symbol = "-"
      break;
    default:
      color = "#2cce8a";
      bg = "#cdecdd";
      symbol = props.amount > 0 ? "+" : "-";
  }
  let amt = props.amount;
  if (amt < 0) amt *= -1;

  return (
    <tr>
      <td>
        <a style={{ color }}>
          {symbol} {Number(amt).toLocaleString(undefined, {
            maximumFractionDigits: 2
          })} €
        </a>
      </td>
      <td>
        <a style={{ color, textTransform: "capitalize" }}>
          {props.client}
        </a>
      </td>
      <td>{moment(props.date).format("DD.MM.YYYY")}</td>
      <td>
        <a
          style={{
            border: `solid 1.5px ${color}`,
            borderRadius: "7px",
            background: bg,
            padding: "2px 8px 2px 8px",
            color: "#000000",
            boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
            textTransform: "capitalize"
          }}
        >
          {transType}
        </a>
      </td>
      <td>
        <div className="btn-group">
          <a
            href="#"
            className="btn btn-light btn-sm"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Chose <i className="fa fa-angle-down"></i>
          </a>
          <div className="dropdown-menu">
            <span
              style={{ cursor: "pointer" }}
              className="dropdown-item"
              onClick={props.setShowEdit}
            >
              <i className="fe fe-edit mr-2"></i> Edit
            </span>
            <span
              style={{ cursor: "pointer" }}
              className="dropdown-item"
              onClick={props.setShowDel}
            >
              <i className="fe fe-trash mr-2"></i> Delete
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default Row;
