import { useContext, useEffect, useState } from "react";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import { AuthContext } from "../../context/AuthContext";
import axios from "../../axios";
import styles from "./index.module.css";

const green = "#40A099";
const red = "#A04040";

const format = (more) => new Intl.NumberFormat('sk-SK', {
  style: "currency",
  currency: "EUR",
  ...more
}).format;


const options = {
  year: 'numeric', // Display the full numeric year
  month: '2-digit', // Display the month with leading zeros (01-12)
  day: '2-digit',  // Display the day with leading zeros (01-31)
  hour: 'numeric', // Display the hour (24-hour format)
  minute: '2-digit', // Display the minute with leading zeros (00-59)
  // You can optionally include seconds: second: '2-digit'
};

const tFormat = (new Intl.DateTimeFormat('sk-SK', options)).format;


const MyTrade = (props) => {
  const auth = useContext(AuthContext);
  const [trades, setTrades] = useState([]);
  const [loading, setLoading] = useState(false);
  useCloseSidebar();


  useEffect(() => {
    if (!props.newTrade) return
    setTrades([{ ...props.newTrade }, ...trades])

    window.$('#client-trades').dataTable().fnAddData(props.newTrade);
  }, [props.newTrade])

  useEffect(() => {
    if (!props.updateTrade) return
    const uTrades = [...trades];
    const idx = uTrades.findIndex(i => i._id === props.updateTrade._id);
    uTrades[idx] = { ...props.updateTrade };
    setTrades(uTrades)

    window.$('#client-trades').dataTable().fnClearTable();
    window.$('#client-trades').dataTable().fnAddData(uTrades);
  }, [props.updateTrade])

  const initDatatable = (trades) => {
    window.$("#client-trades").DataTable({
      lengthChange: false,
      language: {
        searchPlaceholder: "Vyhľadávanie obchodu",
        sSearch: "",
        lengthMenu: "_MENU_ ",
      },
      order: [[0, "desc"], [8, "desc"]],
      data: trades,
      columns: [
        {
          data: "",
          render: function(_, __, r) {
            return r.status === "open" ? `<button
              class="${[styles.btn, styles.red].join(' ')}"
              style="padding: 3px 12px"
              id="${r._id}"
            >
              UZATVORIŤ
            </button>`: "";
          }
        },
        {
          data: "type",
          render: function(_, __, r) {
            const style = `color: ${r.type === 'buy' ? green : red};`;
            return `<span style="${style}">${r.type.toUpperCase()}</span>`
          }
        },
        {
          data: "currency",
          render: function(_, __, r) {
            return `${r.currency?.symbol?.toUpperCase?.()}/USDT`;
          }
        },
        {
          data: "amount",
          render: function(_, __, r) {
            return `<span style="color: ${green}">${format()(r.amount)}</span>`
          }
        },
        {
          data: "result",
          render: function(_, __, r) {
            const color = r.result - r.fee > 0 ? green : red;
            const result = Math.max(r.result - r.fee, -r.amount + r.fee);
            const pl = format({ signDisplay: "never" })(result);
            let symbol = (r.result - r.fee) > 0 ? "+" : "-";
            if (!result || +pl == 0) symbol = "";

            return `<span style="color: ${color}">${symbol}${pl}</span>`
          }
        },
        {
          data: "leverage",
          render: function(_, __, r) {
            return `1:${r.leverage}`
          }
        },
        {
          data: "entry",
          render: function(_, __, r) {
            return `<span class="text-gray">${r.entry}</span>`
          }
        },
        {
          data: "createdAt",
          render: function(_, __, r) {
            return `<span class="text-gray">${tFormat(new Date(r.createdAt))}</span>`
          }
        },
        {
          data: "exit",
          render: function(_, __, r) {
            return `<span class="text-gray">${r.exit && r.status === "closed" ? r.exit : "-"}</span>`
          }
        },
        {
          data: "dateOfExit",
          render: function(_, __, r) {
            return `<span class="text-gray">${r.dateOfExit ? tFormat(new Date(r.dateOfExit)) : "-"}</span>`
          }
        },
        {
          data: "fee",
          render: function(_, __, r) {
            return format()(r.fee);
          }
        },
        {
          data: "status",
          render: function(_, __, r) {
            let sColor = green;
            if (r.status === "closed") sColor = "#777777";

            const style = `color: ${sColor}; text-transform: capitalize`;
            return `<span style="${style}">${r.status === "open" ? "Otvorené" : "Ukončené"}</span>`
          }
        },
      ]
    });
  };


  const getTrades = async (init) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.get(`/trade/${auth.userId}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      const d = res.data?.trades || [];

      setTrades(d);

      if (init) {
        initDatatable(d);
      } else {
        window.$('#client-trades').dataTable().fnClearTable();
        window.$('#client-trades').dataTable().fnAddData(d);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTrades(true);
    const timer = setInterval(getTrades, 10 * 1000);
    return () => {
      clearInterval(timer)
    }
  }, []);

  const handleClick = (e) => {
    const el = e.target;
    const btn = el.closest("button");
    if (!btn) return;
    props.finishTrade(trades.find(i => i._id == btn.id));
  }

  return (
    <div
      className="card"
      style={{
        paddingTop: "20px",
        boxShadow:
          "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
      }}
    >
      <div className="card-header">
        <div
          className="card-title"
          style={{ textTransform: "none", fontSize: "30px" }}
        >
          Moje obchodovanie
        </div>
      </div>
      <div className="card-body" style={{ marginTop: "0" }}>
        <div className="">
          <div className="table-responsive">
            <table
              id="client-trades"
              className="table table-bordered text-nowrap key-buttons"
            >
              <thead>
                <tr className={styles.table_header}>
                  <th className="border-bottom-0"></th>
                  <th className="border-bottom-0">BUY/SELL</th>
                  <th className="border-bottom-0">Pár</th>
                  <th className="border-bottom-0">VKLAD</th>
                  <th className="border-bottom-0">Zisk/strata</th>
                  <th className="border-bottom-0">PÁKA</th>
                  <th className="border-bottom-0">Vstup</th>
                  <th className="border-bottom-0">Dátum VSTUPU</th>
                  <th className="border-bottom-0">VÝSTUP</th>
                  <th className="border-bottom-0">DÁTUM VÝSTUPU</th>
                  <th className="border-bottom-0">Poplatok</th>
                  <th className="border-bottom-0">Stav</th>
                </tr>
              </thead>
              <tbody onClick={handleClick}></tbody>
            </table>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default MyTrade;
