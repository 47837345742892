import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PageNav from "../../components/PageNav";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import styles from "./index.module.css";
import Popup from "./Popup";
import { getStats } from "../Dashboard/utils";
import { AuthContext } from "../../context/AuthContext";
import axios from "../../axios";
import { SidebarContext } from "../../context/SidebarContext";

export default function Wallet() {
  useCloseSidebar();
  const [open, setOpen] = useState(false);
  const [recent, setRecent] = useState([]);
  const [loading, setLoading] = useState(true);

  const auth = useContext(AuthContext);
  const { currency } = useContext(SidebarContext);

  const getRecent = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/user/recent-changes/${currency}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setRecent(res.data?.recent);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getRecent();
  }, []);

  recent.reverse();
  const amount = getStats(recent);
  const capital = amount?.capital
    ? new Intl.NumberFormat("sk-SK", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
    }).format(amount.capital)
    : 0;

  return (
    <Layout pageNav={<PageNav title="EOStrategy Wallet" subtext="Wallet" />}>
      <div className="row">
        <div className={styles.wallet}>
          <p>Pripísaná suma v peňaženke</p>
          <h1>{loading ? "Načítavam . . ." : capital}</h1>
          <button disabled={open || loading} onClick={() => setOpen(true)}>
            Požiadať o predčastný výber
          </button>
        </div>
      </div>
      <Popup
        active={open}
        close={() => setOpen(false)}
        amount={capital}
        token={auth.token}
        max={amount?.capital || 0}
      />
    </Layout>
  );
}
