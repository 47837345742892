import { useContext, useEffect } from "react";
import { SidebarContext } from "../context/SidebarContext";

const useCloseSidebar = () => {
  const sidebar = useContext(SidebarContext);
  useEffect(() => {
    const isOpen = document.querySelector("body.sidenav-toggled");
    if (isOpen && window.innerWidth < 550) {
      document.querySelector(".app-sidebar__toggle").click();
      sidebar?.close();
    }
  }, []);
};

export default useCloseSidebar;
