import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PageNav from "../../components/PageNav";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import axios from "../../axios";
import Pagination from "./Pagination";
import { Link, useSearchParams } from "react-router-dom";
import './index.css'

const LIMIT = 12; 

export default function Blog() {
  useCloseSidebar();
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useSearchParams()
  const pPage = parseInt(search.get('page') || '1'); 
  const [page, setPage] = useState(pPage);
  const [query, setQuery] = useState(search.get('query') || "");

  const getPosts = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`/blog?limit=${LIMIT}&page=${page}&query=${query}`);
      const { count, posts } = res.data || {};
      setPosts(posts || [])
      setTotal(count || 0)
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    setSearch({ page, query })
    getPosts();
  }, [page, query]);

  const handleSearch = e => {
    e.preventDefault();
    const input = e.target.querySelector('input');
    setQuery(input.value);
    setPage(1);
  }

  return (
    <Layout pageNav={<div className="blog-page-header"><PageNav title="Blogs" subtext="blogs" />
      <form onSubmit={handleSearch}>
        <input
          type="text"
          className="form-control"
          placeholder="Hľadať . . ."
        />
      </form>
    </div>}>
      <div className="row">
        {!loading && total === 0 && <h2 className="col-12 mt-4">Nenašli sa žiadne príspevky</h2>}
      </div>

      {loading && <div className="blog-loading">
        <img src="../assets/images/svgs/loader.svg" alt="loader" />
      </div>}

      <div className={`row blog-posts ${loading ? "invisible" : ""}`}>
        {posts && posts.map(itm => {
          const thumb = itm.mainPicture.replace("upload/", "upload/c_fill,w_350,h_300/");
          return (
            <Link className="col-xm-12 col-sm-6 col-lg-4 col-xl-3" to={`/blog/${itm.slug}`} key={itm.slug}>
              <div className="post-card">
                <img src={thumb} />
                <div className="post-card-body">
                  <h2>{itm.title}</h2>
                  <p>{itm.description}</p>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
      {total > LIMIT && <div className="row">
        <Pagination total={total} limit={LIMIT} page={page} setPage={setPage} />
      </div>}
    </Layout>
  );
}
