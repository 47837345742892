const Pagination = ({ total, page, setPage, limit }) => {
  const totalPages = Math.ceil(total / limit);

  const handleClick = (e, v) => {
    e.preventDefault();
    setPage(v)
  }

  const renderItems = () => {
    const markup = [];
    for (let i = 1; i <= totalPages; i++)
      markup.push(<li key={`page_${i}`} className={`page-item ${page === i ? "active" : ""}`}>
        <a className="page-link" href="#" onClick={(e) => handleClick(e, i)}>{i}</a></li>);
    return markup;
  }

  return (
    <nav className="col-12 mt-8">
      <ul className="pagination justify-content-end">
        <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
          <a className="page-link" href="#" onClick={(e) => handleClick(e, page - 1)} tabIndex="-1">
            <i className="fa fa-angle-left"></i>
            <span className="sr-only">Predchádzajúce</span>
          </a>
        </li>
        {renderItems()}
        <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
          <a className="page-link" href="#" onClick={(e) => handleClick(e, page + 1)}>
            <i className="fa fa-angle-right"></i>
            <span className="sr-only">Ďalšie</span>
          </a>
        </li>
      </ul>
    </nav >
  )
}

export default Pagination
