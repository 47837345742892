import { useContext, useState } from "react";
import styles from "./index.module.css";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";


const OpenTrade = (props) => {
  const [state, setState] = useState(null);
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [amount, setAmount] = useState(0);
  const [leverage, setleverage] = useState("1:10");
  const { token } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");
    const type = e.target.id;
    setType(type);
    const parts = leverage.split(":")
    try {
      const res = await axios.post(
        "/trade",
        {
          amount,
          leverage: parseInt(parts[1] / parts[0]),
          type,
          currency: props.pair?._id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      props.setNewTrade({ ...res.data, currency: props.pair });
    } catch (err) {
      const msg = err.response?.data?.error || "Niečo sa pokazilo";
      setStatus(`error:${msg}`);
      return console.error(msg);
    }
    setState(true);
  };

  return state ? (
    <div className={[styles.popup, "fix-bg"].join(" ")}>
      <h2 className={["mt-5", styles.title].join(" ")}>OTVORENÝ OBCHOD</h2>
      <p className="mx-auto" style={{ width: "280px", textAlign: "center" }}>Objednávka bola spracovaná a pridaná od obchodnej tabuľky.</p>
    </div>
  ) : (
    <div className={[styles.popup, "fix-bg"].join(" ")}>
      <h2 className={styles.title}>OTVORTE OBCHOD</h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <img src={props.pair.logo} width="20px" height="20px" />
        <p>{props.pair?.symbol?.toUpperCase()}/USDT</p>
      </div>
      <div className={styles.trade_form}>
        <div className={styles.form_control}>
          <label>Vklad do obchodu</label>
          <input
            min={1}
            className={[styles.input, "fix-bg active"].join(" ")}
            type="number"
            placeholder="Typ investície"
            value={amount}
            onChange={(e) => setAmount(e.target.valueAsNumber)}
          />
        </div>
        <div className={styles.form_control}>
          <label>Zvoľte páku</label>
          <div class="dropdown show">
            <a
              class={["dropdown-toggle", styles.input, "fix-bg active"].join(" ")}
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              style={{ display: "inline-block", width: "100%" }}
              aria-expanded="false"
            >
              {leverage.toUpperCase()}
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <span
                class="dropdown-item"
                role="button"
                onClick={() => setleverage("1:10")}
              >
                1:10
              </span>
              <span
                class="dropdown-item"
                role="button"
                onClick={() => setleverage("1:30")}
              >
                1:30
              </span>
              <span
                class="dropdown-item"
                role="button"
                onClick={() => setleverage("1:50")}
              >
                1:50
              </span>
            </div>
          </div>

        </div>
        <div className={styles.btn_group} style={{ gridColumn: "1/-1" }}>
          <button
            type="button"
            className={[styles.btn].join(" ")}
            onClick={props.close}
          >
            Zrušiť
          </button>
          <button
            disabled={status === "loading"}
            id="sell"
            onClick={handleSubmit}
            className={[styles.btn, styles.red].join(" ")}
          >
            {type === "sell" && status === "loading" ? "SELLING..." : "SELL"}
          </button>
          <button
            disabled={status === "loading"}
            id="buy"
            onClick={handleSubmit}
            className={[styles.btn, styles.green].join(" ")}
          >
            {type === "buy" && status === "loading" ? "BUYING..." : "BUY"}
          </button>
        </div>

        <div>
          {status?.includes("error") && <small className="text-danger">{status.replace("error:", "")}</small>}
        </div>
      </div>
    </div>
  );
};

export default OpenTrade;
