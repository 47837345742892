import React, { useContext, useState } from "react";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";

export default function ContactForm() {
  const initialState = {
    name: { value: "", error: "" },
    subject: { value: "", error: "" },
    message: { value: "", error: "" },
  };
  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const auth = useContext(AuthContext);

  const clearErrors = () => {
    const updatedState = { ...state };
    for (const item in updatedState) {
      updatedState[item].error = "";
    }
    setState(updatedState);
  };

  const onChange = (field, value) => {
    const updated = { ...state };
    updated[field].value = value;
    setState(updated);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setSuccess(false);
    setErrors([]);
    clearErrors();

    try {
      const formData = {};
      for (const item in state) {
        formData[item] = state[item].value;
      }
      const url = "/user/contact";

      await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setSuccess(true);
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Niečo sa pokazilo"]);
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <div className="row align-items-center">
          <label className="col-sm-2 form-label">Vaše meno</label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              value={state.name.value}
              onChange={(e) => onChange("name", e.target.value)}
            />
          </div>
          <small className="col-sm-12 text-danger block mt-1 text-right">
            {state.name.error}
          </small>
        </div>
      </div>
      <div className="form-group">
        <div className="row align-items-center">
          <label className="col-sm-2 form-label">Téma</label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              value={state.subject.value}
              onChange={(e) => onChange("subject", e.target.value)}
            />
          </div>

          <small className="col-sm-12 text-danger block mt-1 text-right">
            {state.subject.error}
          </small>
        </div>
      </div>
      <div className="form-group">
        <div className="row ">
          <label className="col-sm-2 form-label">Správa</label>
          <div className="col-sm-10">
            <textarea
              rows="10"
              className="form-control"
              value={state.message.value}
              onChange={(e) => onChange("message", e.target.value)}
            ></textarea>
          </div>
          <small className="col-sm-12 text-right text-danger block mt-1">
            {state.message.error}
          </small>
        </div>
      </div>
      <div className="btn-list ml-auto">
        <button
          type="submit"
          className="block ml-auto btn btn-primary btn-space"
          disabled={loading}
        >
          {loading ? "Odosiela sa..." : "Odoslať správu"}
        </button>
      </div>
      {success && (
        <small className="text-success block mt-4 text-right">
          Kredit bol úspešne pridaný
        </small>
      )}
      {errors &&
        errors.map((error, i) => (
          <small key={`error-${i}`} className="text-danger block mt-4 text-right">
            {error}
          </small>
        ))}
    </form>
  );
}
