import React, { useContext, useEffect } from "react";
import Layout from "../../components/Layout";
import PageNav from "../../components/PageNav";
import Contract from "./Contract";
import UserData from "./UserData";
import PasswordUpdate from "./PasswordUpdate";
import TwoFact from "./TwoFact";
import useScripts from "../../hooks/useScripts";
import useUserProfile from "../../hooks/useUserProfile";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import useCloseSidebar from "../../hooks/useCloseSidebar";

const scripts = [
  "../assets/plugins/select2/select2.full.min.js",
  "../assets/js/select2.js",
];

export default function UserProfile() {
  useScripts(scripts, true);
  useCloseSidebar();

  const auth = useContext(AuthContext);
  const { state, loading } = useUserProfile(auth.userId);

  useEffect(() => {
    if (auth.isAdmin && !auth.specialToken)
      auth.setSpecialPopup(true)
  }, [])

  const renderUserData = () => {
    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <Contract
          userId={auth.userId}
          token={auth.token}
          specialToken={auth.specialToken}
          isAdmin={auth.isAdmin}
          start={state.startOfContract}
          end={state.endOfContract}
        />
        <UserData {...state} />
      </>
    );
  };

  const title = `${state.fname} ${state.lname} `;

  return (
    <Layout pageNav={<PageNav title={title} subtext="Môj profil" />}>
      {renderUserData()}

      <div className="row">
        <PasswordUpdate userId={auth.userId} specialToken={auth.specialToken} />
        <TwoFact
          userId={auth.userId}
          twoFacEnabled={state.twoFacEnabled}
          token={auth.token}
          specialToken={auth.specialToken}
        />
      </div>
    </Layout>
  );
}
