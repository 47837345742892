import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default function TableItem(props) {
  return (
    <tr>
      <td className="align-middle">
        <div className="d-flex">
          <div className="mt-1">
            <a className="btn-link">
              {props.fname} {props.lname}
            </a>
          </div>
        </div>
      </td>
      <td className="text-nowrap align-middle">
        {/* <span className="font-weight-bold" style={{ color: " #705ec7" }}> */}
        <span className="font-weight-bold" style={{ color: "rgb(1, 150, 177)" }}>
          {parseFloat(props.deposit).toFixed(2)}€
        </span>
      </td>
      <td className="text-nowrap align-middle">
        <span>{moment(props.startOfContract).format("DD.MM.YYYY")}</span>
      </td>
      <td className="text-nowrap align-middle">
        {moment(props.endOfContract).format("DD.MM.YYYY")}
      </td>
      <td className="text-nowrap align-middle" style={{ color: " #2cce8a" }}>
        {parseFloat(props.profit).toFixed(2)}€
      </td>
      <td>
        <div className="btn-group">
          <a
            href="#"
            className="btn btn-light btn-sm"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Vybrať <i className="fa fa-angle-down"></i>
          </a>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to={`/edit-user/${props.id}`}>
              <i className="fe fe-edit mr-2"></i> Upraviť
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}
