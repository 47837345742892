import qr from '../../assets/ar.png'
import styles from './index.module.css'

const Deposit = (props) => {
  return (
    <div  className={styles.popup} style={{background: "#272d47"}}>
      <h2 className={styles.title}>Deposit</h2>
      <h4>Návod na vklad:</h4>
      <div className={styles.deposit}>
        <ol>
          <li>Otvorte crypto peňaženku</li>
          <li>Naskenujte QR kód</li>
          <li>Zvoľte sumu na vklad</li>
          <li>Do poznámky uveďte Vaše menu</li>
        </ol>
        <img src={qr} alt="QR code" />
      </div>
      <button onClick={props.close} className={[styles.btn, styles.white].join(" ")}>ZATVORIŤ</button>
    </div>
  )
}

export default Deposit
