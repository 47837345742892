import "./QRPopup.css"
const QRPopup = (props) => {
  return (
    <>
      <div className={`${props.open && "open"} qr-modal`} onClick={props.close}></div>
      <div className={`${props.open && "open"} qr-container`}>
        <h2>Naskenujte pomocou aplikácie Google Authenticator.</h2>
        <img src={props.url} alt="QR" />
      </div>
    </>
  )
}

export default QRPopup;
