import { useContext, useState } from "react";
import styles from "./index.module.css";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";

const Withdrawal = (props) => {
  const [state, setState] = useState(null);
  const [status, setStatus] = useState(null);
  const { token } = useContext(AuthContext)

  const handleSubmit = async e => {
    e.preventDefault();
    setStatus("loading");
    try {
      const amount = e.target.querySelector("input")?.valueAsNumber;
      await axios.post("/user/withdrawal-request", { amount }, { headers: { Authorization: `Bearer ${token}` } })
      setState("success")
    } catch (err) {
      const msg = err?.response?.data?.message || "Niečo sa pokazilo, skúste to prosím znova"
      return setStatus(`error:` + msg);
    }
    setState(true)
  }

  return state ? (
    <div className={[styles.popup, "fix-bg"].join(" ")}>
      <h2 className={["mt-5", styles.title].join(" ")}>POŽIADAŤ O ODSTÚPENIE OD ZMLUVY</h2>
      <p className="mx-auto" style={{ width: "280px", textAlign: "center" }}>Žiadosť bude spracovaná do niekoľkých hodín po potvrdení správcom</p>
    </div>
  ) : (
    <div className={[styles.popup, "fix-bg"].join(" ")}>
      <h2 className={styles.title}>POŽIADAŤ O VÝBER PROSTRIEDKOV</h2>
      <form className={styles.withdraw_form} onSubmit={handleSubmit}>
        <label>Zadajte sumu, ktorú chcete vybrať</label>
        <input
          min={1}
          className={[styles.input, "fix-bg active"].join(" ")}
          type="number"
          placeholder="Suma na výber"
        />
        <div className={styles.btn_group}>
          <button className={[styles.btn, styles.red].join(" ")} type="button" onClick={props.close}>Zrušiť</button>
          <button className={[styles.btn, styles.green].join(" ")} type="submit" disabled={status === "loading"}>
            {status === "loading" ? "VÝBER" : "VÝBER"}</button>
        </div>
        <div className="mt-4">
          {status && status.split("error:")?.[1] && <small className="text-danger">{status.replace("error:", "")}</small>}
        </div>
      </form>
    </div>
  );
};

export default Withdrawal;
