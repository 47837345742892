import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import axios from "../axios";

const SidebarContext = createContext();

const SidebarState = (props) => {
  const auth = useContext(AuthContext);
  const [state, setState] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const pCurrency = localStorage.getItem("currency") || "";
  const [currency, setCurrency] = useState(pCurrency);

  const switchCurrency = (value) => {
    setCurrency(value);
    localStorage.setItem("currency", value);
    window.location = "/dashboard";
  }

  const getAccounts = async () => {
    try {
      const res = await axios.get("/user/available-currencies", {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setCurrencies(res.data);
      if (pCurrency) return;
      switchCurrency("eur")
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => { getAccounts() }, []);

  return (
    <SidebarContext.Provider
      value={{
        isOpen: state,
        open: () => setState(true),
        close: () => setState(false),
        toggle: () => setState(!state),
        currencies,
        currency,
        switchCurrency
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
};

export { SidebarContext, SidebarState };
