import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import styles from './index.module.css'
import Info from './Info';
import Modal from './Modal';
import Withdrawal from './Withdrawal';
import OpenTrade from './OpenTrade';
import FinishTrade from './FinishTrade';
import Deposit from './Deposit';
import MyTrade from './MyTrades';
import Layout from '../../components/Layout';
import PageNav from '../../components/PageNav';
import Activate from './Activate';
import Chart from './Chart';
import useScripts from '../../hooks/useScripts';
import Loader from '../../components/Loader';
import axios from '../../axios';
import './fix-bg.css'

const scripts = [
  "../assets/plugins/datatable/js/jquery.dataTables.js",
  "../assets/plugins/datatable/js/dataTables.bootstrap4.js",
  "../assets/plugins/datatable/dataTables.responsive.min.js",
  "../assets/plugins/datatable/responsive.bootstrap4.min.js"
];

const UserDashboard = () => {
  const auth = useContext(AuthContext);
  const [loadingScript] = useScripts(scripts, true);
  const [modal, setModal] = useState(false)
  const [tradeStatus, setTradeStatus] = useState(null)
  const [withdraw, setWithdrawal] = useState(false)
  const [openTrade, setOpenTrade] = useState(false)
  const [finishTrade, setFinishTrade] = useState(false)
  const [deposit, setDeposit] = useState(false)
  const [pair, setPair] = useState(null)
  const [newTrade, setNewTrade] = useState(null)
  const [updateTrade, setUpdateTrade] = useState(null)

  useEffect(() => {
    if (modal) return
    setWithdrawal(false)
    setOpenTrade(false)
    setFinishTrade(false)
    setDeposit(false)
  }, [modal])


  const getData = async () => {
    const res = await axios.get(`/user/trade-status?id=${auth.userId}`, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
    const v = res.data;

    setTradeStatus(v?.tradeStatus || false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!openTrade && !deposit && !withdraw && !finishTrade) return
    setModal(true);
    setNewTrade(null);
  }, [openTrade, deposit, withdraw, finishTrade])

  if (loadingScript || tradeStatus === null) return <Loader />

  return (
    <>
      <Layout pageNav={<PageNav title="Trading" subtext="Trading" />}>
        <div style={{ maxWidth: "1300px" }}>
          <Chart pair={pair} setPair={setPair} openDeposit={() => setDeposit(true)} openWithdraw={() => setWithdrawal(true)} />
          <Info update={modal} open={() => setOpenTrade(true)} />
          <MyTrade updateTrade={updateTrade} newTrade={newTrade} finishTrade={setFinishTrade} />
        </div>
      </Layout>

      {modal && <Modal close={() => setModal(false)} />}
      {withdraw && <Withdrawal close={() => setModal(false)} />}
      {openTrade && <OpenTrade setNewTrade={setNewTrade} pair={pair} close={() => setModal(false)} />}
      {finishTrade && <FinishTrade setUpdateTrade={setUpdateTrade} pair={pair} trade={finishTrade} close={() => setModal(false)} />}
      {deposit && <Deposit close={() => setModal(false)} />}

      {tradeStatus !== "ok" && <div className={[styles.modal, styles.backdrop].join(" ")} />}
      {tradeStatus !== "ok" && <Activate tradeStatus={tradeStatus} />}
    </>
  )
}

export default UserDashboard

