import { useContext, useState } from "react";
import { SidebarContext } from "../../context/SidebarContext";

const SwitchCurrency = () => {
  const { currencies, switchCurrency } = useContext(SidebarContext);
  const [open, setOpen] = useState(false);

  return (
    <li className={`slide ${open ? "is-expanded" : ""}`}>
      <a
        onClick={(e) => { e.preventDefault(); setOpen(!open) }}
        className="side-menu__item"
        data-bs-toggle="slide"
        href="#"
      >
        <svg
          className="side-menu__icon"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"
          /></svg
        >
        <span className="side-menu__label">Prepnúť účet</span>
        <i
          style={{ transition: `rotate 100ms`, rotate: open ? "180deg" : "" }}
          className="fe fe-chevron-down"
        ></i>
      </a>
      <ul
        className={`slide-menu ${open ? "open" : ""}`}
        style={{ display: open ? "block" : "none" }}
      >
        <li className="panel sidetab-menu">
          <div className="panel-body tabs-menu-body p-0 border-0">
            <div className="tab-content">
              <div className="tab-pane tab-content-show active">
                <ul className="sidemenu-list">
                  {currencies.map((itm) => (
                    <li key={itm}>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          switchCurrency(itm);
                        }}
                        style={{ cursor: "pointer" }}
                        className="slide-item"
                      >
                        {itm?.toUpperCase?.()} - {itm == "eur" ? "Klasický" : "Index"} účet
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </li>
  );
};

export default SwitchCurrency;
