import { useContext, useEffect, useState } from "react";
import axios from "../axios";

import { AuthContext } from "../context/AuthContext";
import { SidebarContext } from "../context/SidebarContext";

export default function useUserProfile(userId) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  const auth = useContext(AuthContext);
  const { currency } = useContext(SidebarContext);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/user/profile?id=${userId}&currency=${currency}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setState(res.data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!userId) return;
    getData();
  }, []);

  return { state, loading };
}
