import React from "react";
import { Link } from "react-router-dom";
import './HeaderLogo.css'

export default function HeaderLogo() {
  return (
    <Link className="header-brand" to={"/dashboard"}>
      <img
        src="../assets/images/brand/Logo-Base-Black.svg"
        className="header-brand-img desktop-lgo"
        alt="Admitro logo"
      />
      <img
        src="../assets/images/brand/Logo-Base-Black.svg"
        className="header-brand-img dark-logo"
        alt="Admitro logo"
      />
      <img
        src="../assets/images/brand/Logo-Short-Black.svg"
        className="header-brand-img mobile-logo"
        alt="Admitro logo"
      />
      <img
        src="../assets/images/brand/Logo-Short-Black.svg"
        className="header-brand-img darkmobile-logo"
        alt="Admitro logo"
      />
    </Link>
  );
}
