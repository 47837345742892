import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";
import styles from './Switch.module.css';
import { useContext, useEffect, useState } from 'react'

const Switch = (props) => {
  const [state, setState] = useState("loading");
  const auth = useContext(AuthContext);

  const toggle = async (e) => {
    const checked = e.target.checked;
    await axios.patch(`/user/trade-status?id=${props.id}`, { status: checked ? "ok" : null }, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
    setState(checked);
  }

  const getData = async (id) => {
    const res = await axios.get(`/user/trade-status?id=${id}`, {
      headers: { Authorization: `Bearer ${auth.token}` },
    });
    setState(res.data?.tradeStatus === "ok");
  };

  useEffect(() => {
    getData(props.id)
  }, [props.id]);

  if (state === "loading") return null

  return (
    <label className={styles.switch}>
      <input checked={state} onChange={toggle} type="checkbox" />
      <div className={styles.button}>
        <div className={styles.light}></div>
        <div className={styles.dots}></div>
        <div className={styles.characters}></div>
        <div className={styles.shine}></div>
        <div className={styles.shadow}></div>
      </div>
    </label>
  )
}

export default Switch
