import moment from "moment";
import "moment/locale/sk";
import {
  fillBeforeLatest,
  fillGaps,
  fillWithBeforeLast,
  getCapital,
  getProfit,
  stripExtra,
} from "./chart/utils.js";

moment.locale("sk");

const engMonths = {
  jan: "jan",
  feb: "feb",
  mar: "mar",
  apr: "apr",
  máj: "may",
  jún: "june",
  júl: "july",
  aug: "aug",
  sep: "sep",
  okt: "oct",
  nov: "nov",
  dec: "dec",
};

const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "máj",
  "jún",
  "júl",
  "aug",
  "sep",
  "okt",
  "nov",
  "dec",
];

let transMonths = [];
let transMonthsYears = {};

const log = (msg, obj) => console.log(msg, JSON.parse(JSON.stringify(obj)));

/**
 * Get Data for 1 year
 * @recent - array of all transaction
 * @type - type of transaction to calculate
*  @return an array of transactions. 
*  If it's for monthly profit then it will return 12 items which contains profit for each month
 **/
const getYearData = (recent, type) => {
  // sorting the array so now we have the oldest transaction at first.
  const recentR = recent.reverse();
  let data;
  if (type === "profit") {
    data = getProfit(recentR);
  } else {
    data = getCapital(recentR);
  }
  // result will be in desending order.
  const d = data[0]?.dateCreated;
  const firstMonth = parseInt(moment(d).format("M"));

  // fill last 12 months
  const forLast12Months = stripExtra(data, "M", firstMonth, "months", 12);

  const ld = recentR[recentR.length - 1]?.dateCreated;
  const lFirstMonth = parseInt(moment(ld).format('M'))
  fillBeforeLatest(forLast12Months, ld, d, "months");
  fillGaps(forLast12Months, "months");
  fillWithBeforeLast(data, forLast12Months, "months", 11);

  return { forLast12Months, lFirstMonth };
};

const initMonths = () => {
  const result = {};
  for (const month of months) {
    result[month] = -1;
  }
  return result;
};

const setMonthlydata = (result, forLast12Months) => {
  for (const item of forLast12Months) {
    const month = moment(item.dateCreated).format("MMM");
    const year = moment(item.dateCreated).format("YYYY");
    if (result[month] > 0) continue;
    result[month] = Number(+parseFloat(item.amount).toFixed(2));
    transMonthsYears[month] = year;
  }
};

const transformData = (firstMonth, result) => {
  const resultArr = [];
  let currentMonth = firstMonth;
  let isNotFilled = transMonths.length === 0;
  if (!isNotFilled) {
    const m = engMonths[transMonths[transMonths.length - 1]];
    const date = `1 ${m} 2023`;

    currentMonth = moment(date).format("M");
  }

  for (const month in result) {
    // console.log({ currentMonth });
    const monthSymbol = moment()
      .month(`${currentMonth - 1}`)
      .format("MMM");
    if (isNotFilled) {
      transMonths.unshift(monthSymbol);
    }

    const amount = result[monthSymbol];
    resultArr.unshift(amount);
    currentMonth--;
    if (currentMonth < 1) currentMonth = 12;
  }

  let lastMonth = 0;
  // fill the empty spaces in between
  for (let i = 0; i < resultArr.length; i++) {
    const item = resultArr[i];
    if (item === -1) resultArr[i] = lastMonth;
    else lastMonth = item;
  }

  // set float to max 2 decimal
  for (let i = 0; i < resultArr.length; i++) {
    resultArr[i] = +parseFloat(resultArr[i]).toFixed(2);
  }

  return resultArr;
};

/**
 * Get Monthly Data
 * @data - An array of all transaction
 * @type - Profit or Capital
 **/
const getMonthlyData = (data, type) => {
  if (data.length < 1) return [];
  const { forLast12Months, lFirstMonth } = getYearData(data, type);
  const result = initMonths();
  setMonthlydata(result, forLast12Months);
  const resultArr = transformData(lFirstMonth, result);
  return resultArr;
};

export { getMonthlyData, transMonths, transMonthsYears };
