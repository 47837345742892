import React, { useContext } from "react";
import { InfoContext } from "../../context/InfoContext";
import Card from "./Card";
// import ContractInfo from "./ContractInfo";
import { cardData, extraData, getCapital, getDeposit, getProfit, getStats, getWithdrawal } from './utils';

export default function Overview({ recent, extra, isAdmin }) {
  recent = recent.reverse();
  const info = useContext(InfoContext);
  if (!info) return null;

  const renderExtra = () => {
    if (!extra) return;

    const stats = getStats(extra);

    const { color, adminTitle, userTitle, type, dataName } = extraData;
    const data = getCapital(extra);

    return (
      <Card
        color={color}
        adminTitle={adminTitle}
        userTitle={userTitle}
        isAdmin={isAdmin}
        amount={stats[type]}
        data={data}
        dataName={dataName}
        larger={true}
      />
    );
  };

  const renderCards = () => {
    const stats = getStats(recent);

    return cardData.map((card, index) => {
      // if (card.admin && !isAdmin) return;
      const { color, adminTitle, userTitle, type, dataName } = card;
      let data;
      switch (type) {
        case "capital":
          data = getCapital(recent);
          break;
        case "profit":
          data = getProfit(recent)
          break;
        case "deposit":
          data = getDeposit(recent);
          break;
        case "withdrawal":
          data = getWithdrawal(recent);
          break;
        default:
          throw new Error("Unknown type");
      }

      return (
        <Card
          key={`card-${index}`}
          color={color}
          adminTitle={adminTitle}
          userTitle={userTitle}
          isAdmin={isAdmin}
          amount={stats[type]}
          data={data}
          dataName={dataName}
        />
      );
    });
  };

  return (
    <>
      <div className="row">
        {renderCards()}
        {/* {!isAdmin && <ContractInfo info={info} />} */}
      </div>
      <div className="row">
        {renderExtra()}
      </div>
    </>
  );
}
