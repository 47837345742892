import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import useScripts from "../../hooks/useScripts";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";



export default function Verify() {
  useScripts([], true);
  const auth = useContext(AuthContext);
  const params = useParams();
  const initialState = {
    code: { value: "", error: "" },
    userId: {value: params.userId, error: ""}
  };
  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);

    const updatedState = { ...state };
    updatedState.code.error = "";
    setState(updatedState);

    try {
      const res = await axios.post(`/user/verify-twofac`, {
        userId: state.userId.value,
        code: state.code.value,
      });
      auth.login(res.data.token);
    } catch (err) {
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Niečo sa pokazilo"]);
      }
    }

    setLoading(false);
  };

  const renderErrors = () =>
    errors.map((error) => (
      <small key={error} className="text-danger block mt-1 text-center">
        {error}
      </small>
    ));

  return (
    <div className="page" style={{background: "white"}}>
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="card" style={{ boxShadow: "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0) 0px -3px 0px inset", backgroundColor: "#171a21", borderRadius: "25px", border: "0.5px solid rgb(0 0 0)", }} >
                    <div
                      className="card-body"
                      style={{ padding: "2.5rem 1.5rem" }}
                    >
                      {/*<div className="text-center title-style mb-6">
                        <h1
                          className="mb-2"
                          style={{ fontWeight: "bold", pointerEvents: "none", background: "none", color: "#0096b1",}}
                        >
                          LONG BOW
                        </h1>

                       
                        
                      </div>*/}
                       <p style={{ textAlign: "center", color: "#ffffff", position: "relative", bottom: "10px", fontWeight: "bold", fontSize: "18px", pointerEvents: "none" }}>Zadajte Google Authenticator</p>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text" style={{ backgroundColor: "rgb(23, 26, 33)", border: "1px solid rgb(23, 26, 33)" }}>
                            <i className="fe fe-user" style={{ color: "#fff" }}></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Overovací kód"
                          value={state.code.value}
                          onChange={(e) =>
                            handleInput("code", e.target.value)
                          }
                          style={{ border: "1px solid rgb(21 23 26)", borderRadius: "10px", boxShadow: "inset rgb(0 0 0 / 25%) 1.95px 1.95px 2.6px" }}/>
                      </div>
                      <small className="text-danger block mb-4 mt-1" style={{ textAlign: "center",}}>
                        {state.code.error}
                      </small>

                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn  btn-primary btn-block px-4"
                            onClick={handleSubmit}
                            disabled={loading}
                            style={{ borderRadius: "10px", backgroundColor: "#41454f", borderColor: "#41454f", boxShadow: "0 0 10px -5px rgb(0 0 0)", }}>
                            {loading ? "Prihlasovanie" : "Prihlásiť"}
                          </button>
                        </div>

                        <div className="col-12">{renderErrors()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
