import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import useScripts from "../../hooks/useScripts";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const initialState = {
  identity: { value: "", error: "" },
  password: { value: "", error: "" },
};

export default function SignIn() {
  useScripts([], true);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);

    const updatedState = { ...state };
    updatedState.password.error = "";
    updatedState.identity.error = "";
    setState(updatedState);

    try {
      const res = await axios.post(`/user/login`, {
        identity: state.identity.value,
        password: state.password.value,
      });
      if (res.data.status === "verify") {
        navigate(`/verify-2fa/${res.data.userId}`);
      } else {
        auth.login(res.data.token);
      }
    } catch (err) {
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Niečo sa pokazilo"]);
      }
    }

    setLoading(false);
  };

  const renderErrors = () =>
    errors.map((error) => (
      <small key={error} className="text-danger block mt-1 text-center">
        {error}
      </small>
    ));

  return (
    <div className="page login-container">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div
                    className="card"
                    style={{
                      boxShadow:
                        "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0) 0px -3px 0px inset",
                      backgroundColor: "#062d51",
                      borderRadius: "25px",
                      border: "1px solid rgb(0 0 0)",
                    }}
                  >
                    <div
                      className="card-body"
                      style={{ padding: "2.5rem 1.5rem" }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          color: "#ffffff",
                          position: "relative",
                          bottom: "10px",
                          fontWeight: "bold",
                          fontSize: "18px",
                          pointerEvents: "none",
                        }}
                      >
                        Prihláste sa do svojho účtu
                      </p>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{
                              backgroundColor: "#062d51",
                              color: "white",
                              border: "none"
                            }}
                          >
                            <i
                              className="fe fe-user"
                              style={{ color: "#fff" }}
                            ></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Užívateľské meno / email"
                          value={state.identity.value}
                          onChange={(e) =>
                            handleInput("identity", e.target.value)
                          }
                          style={{
                            backgroundColor: "#072d51",
                            border: "1px solid rgb(21 23 26)",
                            borderRadius: "10px",
                            color: "white",
                            boxShadow:
                              "inset rgb(0 0 0 / 30%) 2px 2px 2.6px",
                          }}
                        />
                      </div>
                      <small
                        className="text-danger block mb-4 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        {state.identity.error}
                      </small>

                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{
                              backgroundColor: "#062d51",
                              border: "none"
                            }}
                          >
                            <i
                              className="fe fe-lock"
                              style={{ color: "#fff" }}
                            ></i>
                          </div>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Heslo"
                          value={state.password.value}
                          onChange={(e) =>
                            handleInput("password", e.target.value)
                          }
                          style={{
                            backgroundColor: "#072d51",
                            border: "1px solid rgb(21 23 26)",
                            borderRadius: "10px",
                            color: "white",
                            boxShadow:
                              "inset rgb(0 0 0 / 30%) 2px 2px 2.6px",
                          }}
                        />
                      </div>
                      <small
                        className="text-danger block mb-4 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        {state.password.error}
                      </small>

                      <Link to="/forgot-password">
                        <p style={{ textAlign: "center", color: "#ffffff" }}>
                          Zabudnuté heslo?
                        </p>
                      </Link>

                      <Link to="/sign-up">
                        <p style={{ textAlign: "center", color: "#ffffff" }}>
                          Registrácia
                        </p>
                      </Link>

                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn  btn-primary btn-block px-4"
                            onClick={handleSubmit}
                            disabled={loading}
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "#0a4b70",
                              borderColor: "#000",
                              boxShadow: "0 0 10px -5px rgb(0 0 0)",
                            }}
                          >
                            {loading ? "Prihlasovanie" : "Prihlásiť"}
                          </button>
                        </div>

                        <div className="col-12">{renderErrors()}</div>
                      </div>

                      {/* <Link to="/register"> */}
                      {/*   <p style={{ marginTop: '1rem', marginBottom: "0", textAlign: "center", color: "#ffffff" }}> */}
                      {/*     Zaregistruj sa */}
                      {/*   </p> */}
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
